import {Farm} from './farm.model';

export class Loan{
  constructor(
    id?: number,
    dateLoan?: string,
    farmId?: number,
    farm?: Farm,
    withdrawnFundId?: number,
    reimbursementFundId?: number,
    paid?: boolean,
    payday?: string,
    valueLoan?: number,
    description?: string
  ) {
    this.id = id;
    this.dateLoan = dateLoan;
    this.farmId = farmId;
    this.farm = farm;
    this.withdrawnFundId = withdrawnFundId;
    this.reimbursementFundId = reimbursementFundId;
    this.paid = paid;
    this.payday = payday;
    this.valueLoan = valueLoan;
    this.description = description;
  }
  id: number;
  dateLoan: string;
  farmId: number;
  farm: Farm;
  withdrawnFundId: number;
  reimbursementFundId: number;
  paid: boolean;
  payday: string;
  valueLoan: number;
  description: string;
}
