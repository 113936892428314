import { PriceTableItem } from "."; 

export class PricesTable{
  constructor(id?: number, description?: string, items?: PriceTableItem[], active?: boolean){
    this.id = id;
    this.description = description;
    this.items = items;
    this.active = active;
  }
  
  id?: number;  
  description?: string;
  items?: PriceTableItem[];
  active?: boolean;
}