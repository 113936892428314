import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Expand } from 'src/app/shared';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExpandService {
  constructor(private apiService: ApiService) {}

  protected jsonDataToResources(jsonData: any[]): Expand[] {
    const elements: Expand[] = [];
    jsonData.forEach((element) =>
      elements.push(Object.assign(new Expand(), element))
    );
    return elements;
  }

  protected jsonDataToResource(jsonData: any): Expand {
    return Object.assign(new Expand(), jsonData);
  }

  getAll(pageNumber: number, filters: any[]): Observable<any> {
    const params: any = { page: pageNumber, size: 15 };
    Object.assign(params, filters);
    return this.apiService.get(`/duplicata`, params);
  }

  getOutstanding(): Observable<Expand[]> {
    return this.apiService
      .get('/duplicata/aberto')
      .pipe(map(this.jsonDataToResources));
  }

  getById(id: number): Observable<Expand> {
    return this.apiService
      .get(`/duplicata/${id}`)
      .pipe(map(this.jsonDataToResource));
  }

  create(expand: Expand): Observable<Expand> {
    return this.apiService
      .post('/duplicata', expand)
      .pipe(map(this.jsonDataToResource));
  }

  pay(expandId: number, fundId: number): Observable<Expand> {
    return this.apiService
      .post(`/duplicata/${expandId}?fundId=${fundId}`, {})
      .pipe(map(this.jsonDataToResource));
  }

  update(expand: Expand): Observable<Expand> {
    return this.apiService
      .put(`/duplicata/${expand.id}`, expand)
      .pipe(map(this.jsonDataToResource));
  }

  delete(expandId: number): Observable<Expand> {
    return this.apiService.delete(`/duplicata/${expandId}`);
  }
}
