import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'src/app/core';
import { FileMetadata } from '../../models';

interface fileType {
  typeIdentifier?: string;
  type?: string;
}

@Component({
  selector: 'app-input-file-box',
  templateUrl: './input-file-box.component.html',
  styleUrls: ['./input-file-box.component.css'],
})
export class InputFileBoxComponent implements OnInit {
  constructor(
    private fileService: FileService,
    private toastr: ToastrService
  ) {}

  @Input() maxItems: number;
  @Input() fileType: fileType;

  files: FileMetadata[] = [];
  isFetching = false;

  @ViewChild('fileDropRef', { static: false }) fileDropEl!: ElementRef;

  ngOnInit(): void {}

  onFileDropped(event: any): void {
    this.prepareFilesList(event);
  }

  fileBrowseHandler(event: any): void {
    this.prepareFilesList(event.target.files);
  }

  prepareFilesList(files: Array<File>): void {
    if (this.isFetching) {
      return;
    }
    files = [...files];
    if (files.length + this.files.length > this.maxItems) {
      files.length = this.maxItems - this.files.length;
      this.toastr.info(`Insira no máximo ${this.maxItems} itens`);
    }

    files.forEach((file) => this.addFileToArray(file));
  }

  addFileToArray(file: File): void {
    this.isFetching = true;
    this.fileService.uploadFile(file).subscribe(
      (fileMetadata) => {
        this.files.push(fileMetadata);
        this.isFetching = false;
      },
      () => {
        this.toastr.error('Erro ao fazer upload do arquivo');
        this.isFetching = false;
      }
    );
  }

  deleteFromArray(index: number): void {
    this.files.splice(index, 1);
  }

  downloadFile(link: string, event: any): void {
    event.preventDefault();
    window.open(link, '_blank');
  }
}
