import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';
import {Farm} from '../../models';

@Component({
  selector: 'app-select-farm-modal',
  templateUrl: './select-farm-modal.component.html'
})
export class SelectFarmModalComponent implements OnInit, AfterViewInit {
  @ViewChild('farmName') farmName: ElementRef | undefined;

  constructor(
    private activeModal: NgbActiveModal,
    private toastr: ToastrService
  ){
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.farmName?.nativeElement.focus();
  }

  actionsForError(): void{
    this.toastr.error('Erro ao carregar as propriedades');
    this.activeModal.dismiss();
  }

  handleSubmit(farm: Farm): void{
    this.activeModal.close(farm);
  }

  handleCancel(): void{
    this.activeModal.dismiss();
  }

}
