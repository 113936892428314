import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FormOfPayment } from 'src/app/shared';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FormOfPaymentService {
  constructor(private apiService: ApiService) {}

  protected jsonDataToResources(jsonData: any[]): FormOfPayment[] {
    const elements: FormOfPayment[] = [];
    jsonData.forEach((element) =>
      elements.push(Object.assign(new FormOfPayment(), element))
    );
    return elements;
  }

  protected jsonDataToResource(jsonData: any): FormOfPayment {
    return Object.assign(new FormOfPayment(), jsonData);
  }

  getAll(): Observable<FormOfPayment[]> {
    return this.apiService
      .get('/forma-pagamento')
      .pipe(map(this.jsonDataToResources));
  }

  getById(id: number): Observable<FormOfPayment> {
    return this.apiService
      .get(`/forma-pagamento/${id}`)
      .pipe(map(this.jsonDataToResource));
  }

  create(formOfPayment: FormOfPayment): Observable<FormOfPayment> {
    return this.apiService
      .post('/forma-pagamento', formOfPayment)
      .pipe(map(this.jsonDataToResource));
  }

  update(formOfPayment: FormOfPayment): Observable<FormOfPayment> {
    return this.apiService
      .put(`/forma-pagamento/${formOfPayment.id}`, formOfPayment)
      .pipe(map(this.jsonDataToResource));
  }

  delete(formOfPayment: FormOfPayment): Observable<FormOfPayment> {
    return this.apiService.delete(`/forma-pagamento/${formOfPayment.id}`);
  }
}
