import { MeasurementUnity, ProductType } from '.';
import { Farm } from './farm.model';

export class FarmProduct {
  constructor(
    id?: number,
    productType?: ProductType,
    productTypeId?: number,
    farm?: Farm,
    farmId?: number,
    measurementUnity?: MeasurementUnity,
    organic?: boolean,
    active?: boolean,
    barCode?: string
  ) {
    this.id = id;
    this.productType = productType;
    this.productTypeId = productTypeId;
    this.farm = farm;
    this.farmId = farmId;
    this.measurementUnity = measurementUnity;
    this.organic = organic;
    this.active = active;
    this.barCode = barCode;
  }

  id?: number;
  productType?: ProductType;
  productTypeId?: number;
  farm?: Farm;
  farmId?: number;
  measurementUnity?: MeasurementUnity;
  organic?: boolean;
  active?: boolean;
  barCode?: string;

  get keywords(): string {
    return `${this.productType.productName} ${this.barCode}`;
  }
}
