import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared';

import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { NgxMaskModule } from 'ngx-mask';
import { ThermalPrintModule } from 'ng-thermal-print';
registerLocaleData(ptBr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgbModule,
    CoreModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    ThermalPrintModule,
    SharedModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
