import {AbstractControl, ValidatorFn} from '@angular/forms';

export function validateCNPJ(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let cnpj = control.value;
    cnpj = cnpj.replace(/[^\d]+/g, '');

    const match = cnpj.toString().match(/\d/g);
    const numbers = Array.isArray(match) ? match.map(Number) : [];

    if (numbers.length !== 14) {
      return {cnpj: true};
    }
    const items = [...new Set(numbers)];
    if (items.length === 1) {
      return {cnpj: true};
    }

    const calc = (x: number) => {
      const slice = numbers.slice(0, x);
      let factor = x - 7;
      let sum = 0;

      for (let i = x; i >= 1; i--) {
        const n = slice[x - i];
        sum += n * factor--;
        if (factor < 2) {
          factor = 9;
        }
      }

      const result = 11 - (sum % 11);

      return result > 9 ? 0 : result;
    };

    const digits = numbers.slice(12);

    const digit0 = calc(12);
    if (digit0 !== digits[0]) {
      return {cnpj: true};
    }

    const digit1 = calc(13);
    return digit1 === digits[1] ? null : {cnpj: true};
  };
}
