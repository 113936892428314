import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { catchError } from 'rxjs/operators';

import { Address } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class BrasilApiService {
  constructor(private http: HttpClient) {}

  url = 'https://brasilapi.com.br/api/';

  private static formatErrors(error: any): any {
    return throwError(error.error);
  }

  private get(path: string): Observable<any> {
    return this.http
      .get(`${this.url}${path}`)
      .pipe(catchError(BrasilApiService.formatErrors));
  }

  getAddressByCEP(cep: string): Observable<Address> {
    return this.get(`cep/v1/${cep}`).pipe(map((data) => data));
  }

  getCompanyInformationByCNPJ(cnpj: string): Observable<any> {
    return this.get(`cnpj/v1/${cnpj}`).pipe(map((data) => data));
  }
}
