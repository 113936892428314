import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PriceTableItem} from 'src/app/shared';

import {ApiService} from './api.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PriceTableItemService {
  constructor(
    private apiService: ApiService
  ) {
  }

  protected jsonDataToResources(jsonData: any[]): PriceTableItem[] {
    const elements: PriceTableItem[] = [];
    jsonData.forEach((element) =>
      elements.push(Object.assign(new PriceTableItem(), element))
    );
    return elements;
  }

  protected jsonDataToResource(jsonData: any): PriceTableItem {
    return Object.assign(new PriceTableItem(), jsonData);
  }

  getByPriceTableId(priceTableId: number): Observable<PriceTableItem[]> {
    return this.apiService
      .get(`/item-tabela-preco/tabela/${priceTableId}`)
      .pipe(map(this.jsonDataToResources));
  }

  getById(id: number): Observable<PriceTableItem> {
    return this.apiService
      .get(`/item-tabela-preco/${id}`)
      .pipe(map(this.jsonDataToResource));
  }

  create(priceTableItem: PriceTableItem, pricesTableId: number): Observable<PriceTableItem> {
    return this.apiService
      .post(`/item-tabela-preco/${pricesTableId}`, priceTableItem)
      .pipe(map(this.jsonDataToResource)
      );
  }

  update(priceTableItem: PriceTableItem): Observable<PriceTableItem> {
    return this.apiService
      .put(`/item-tabela-preco/${priceTableItem.id}`, priceTableItem)
      .pipe(map(this.jsonDataToResource)
      );
  }

  delete(priceTableItem: PriceTableItem): Observable<PriceTableItem> {
    return this.apiService
      .delete(`/item-tabela-preco/${priceTableItem.id}`)
      .pipe(map(this.jsonDataToResource));
  }


}
