import { FileMetadata } from '.';
import { Farmer } from './farmer.model';

export class Farm {
  id?: number;
  name?: string;
  familyName?: string;
  description?: string;
  email?: string;
  logo?: FileMetadata;
  logoArray?: FileMetadata[];
  images?: FileMetadata[];
  latitude?: string;
  longitude?: string;
  legalGuardianName?: string;
  legalGuardianCPF?: string;
  dap?: string;
  howToArrive?: string;
  active?: boolean;
  farmer?: Farmer;
  farmerName?: string;
  hasLegalGuardian?: boolean;
  whatsapp?: string;
  twitter?: string;
  instagram?: string;
  facebook?: string;
  url?: string;
  qrCodeUrl?: string;

  get keywords(): string {
    return `${this.name} ${this.farmerName}`;
  }
}
