import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

import { User } from 'src/app/shared';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.userSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('user'))
    );
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  get isLoggedIn(): Observable<User> {
    return this.userSubject.asObservable();
  }

  login(login: string, password: string): Observable<User> {
    return this.apiService.post(`/users/auth/admin`, { login, password }).pipe(
      map((user) => {
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        return user;
      })
    );
  }

  requireLogin(): void {
    this.toastr.clear();
    this.toastr.info('Realize o login');
  }

  logout(): void {
    localStorage.removeItem('user');
    this.userSubject.next(null);
    this.router.navigate(['/login'], {
      queryParams: { returnUrl: this.router.url },
    });
  }

  register(user: User): Observable<User> {
    return this.apiService.post(`/users/admin`, user);
  }

  getAll(): Observable<User[]> {
    return this.apiService.get(`/users/admin`);
  }

  getById(id: number): Observable<User> {
    return this.apiService.get(`/users/admin/${id}`);
  }

  update(id: number, user: User): Observable<User> {
    return this.apiService.put(`/users/admin/${id}`, user).pipe(
      map((x) => {
        if (id === this.userValue.id) {
          const updatedUser = { ...this.userValue, ...user };
          localStorage.setItem('user', JSON.stringify(updatedUser));

          this.userSubject.next(updatedUser);
        }
        return x;
      })
    );
  }

  delete(id: number): any {
    return this.apiService.delete(`/users/admin/${id}`).pipe(
      map((x) => {
        if (id === this.userValue.id) {
          this.logout();
        }
        return x;
      })
    );
  }
}
