export class MeasurementUnity {
  constructor(id?: number, unity?: string, acronym?: string, decimal?: boolean) {
    this.id = id;
    this.unity = unity;
    this.acronym = acronym;
    this.decimal = decimal;
  }

  id?: number;
  unity?: string;
  acronym?: string;
  decimal?: boolean;
}
