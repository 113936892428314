<div class="container me-2">
  <div
    class="file-box-container row"
    [ngClass]="{'loading': isFetching}"
    appFileBox
    (fileDropped)="onFileDropped($event)"
  >
  <app-simple-loader *ngIf="isFetching"></app-simple-loader>
  <div *ngIf="files.length == 0">
      <input
        type="file"
        multiple
        (change)="fileBrowseHandler($event)"
        [accept]="fileType.typeIdentifier"
        [disabled]="isFetching"
      />
      <img
        src="assets/img/dnd/ic-upload-file.svg"
        width="35"
        height="35"
        alt=""
      />
      <p class="h4 mt-2">Arraste e solte</p>
      <p class="h4">ou</p>
      <label>Procurar Arquivos</label>
    </div>

    <div class="col mb-2" *ngFor="let file of files; let i = index">
      <img
        *ngIf="file.fileType.includes('image')"
        [src]="file.downloadUri"
        width="120px"
        height="120px"
        alt="file"
        (click)="downloadFile(file.downloadUri, $event)"
      /><br />
      <button
        *ngIf="!file.fileType.includes('image')"
        class="fa fa-file-pdf"
        (click)="downloadFile(file.downloadUri, $event)"
      ></button>

      <div class="delete-icon">
        <img
          class="align-self-center"
          src="assets/img/dnd/ic-delete-file.svg"
          class="delete mt-4"
          width="15px"
          alt="file"
          (click)="deleteFromArray(i)"
        />
      </div>
    </div>
  </div>
</div>
