export class SaleItem {
  constructor(
    productId?: number,
    amount?: number,
    productName?: string,
    measurementUnity?: string,
    price?: number
  ) {
    this.productId = productId;
    this.amount = amount;
    this.price = price;
    this.productName = productName;
    this.measurementUnity = measurementUnity;
  }
  productId?: number;
  amount?: number;
  price?: number;
  productName?: string;
  measurementUnity?: string;
}
