import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Customer, Payment } from 'src/app/shared';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private apiService: ApiService) {}

  protected jsonDataToResources(jsonData: any[]): Customer[] {
    const elements: Customer[] = [];
    jsonData.forEach((element) =>
      elements.push(Object.assign(new Customer(), element))
    );
    return elements;
  }

  protected jsonDataToResource(jsonData: any): Customer {
    return Object.assign(new Customer(), jsonData);
  }

  getAll(): Observable<Customer[]> {
    return this.apiService
      .get('/cliente/todos')
      .pipe(map(this.jsonDataToResources));
  }

  getAllActives(): Observable<Customer[]> {
    return this.apiService.get('/cliente').pipe(map(this.jsonDataToResources));
  }

  getAllForSelect(): Observable<Customer[]> {
    return this.apiService
      .get('/cliente/select')
      .pipe(map(this.jsonDataToResources));
  }

  getById(id: number): Observable<Customer> {
    return this.apiService
      .get(`/cliente/${id}`)
      .pipe(map(this.jsonDataToResource));
  }

  create(customer: Customer): Observable<Customer> {
    return this.apiService
      .post('/cliente', customer)
      .pipe(map(this.jsonDataToResource));
  }

  update(customer: Customer): Observable<Customer> {
    return this.apiService
      .put(`/cliente/${customer.id}`, customer)
      .pipe(map(this.jsonDataToResource));
  }

  disable(customerId: number): Observable<Customer> {
    return this.apiService.patch(`/cliente/ativo/${customerId}`, {});
  }

  delete(customer: Customer): Observable<Customer> {
    return this.apiService.delete(`/cliente/${customer.id}`);
  }

  receiveDebt(
    customer: Customer,
    payments: Payment[]
  ): Observable<Customer> {
    return this.apiService.post('/cliente/recebendo', {
      customerId: customer.id,
      payments
    });
  }
}
