<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="selectFarmModalTitle">Selecione o Produto</h5>
    <button type="button" class="btn-close shadow-none" (click)="handleCancel()" aria-label="Close" style="outline: none;">
    </button>
  </div>
  <div class="modal-body">
    <div class="m-2">
      <app-product-select
        (error)="actionsForError()"
        (productSelected)="handleSubmit($event)"
      ></app-product-select>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="handleCancel()">Cancelar</button>
  </div>
</div>
