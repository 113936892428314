<div class="d-flex justify-content-center">
  <div class="ng-autocomplete">
    <label>Propriedade</label>
    <ng-autocomplete
      [data]="farmArray"
      [searchKeyword]="'keywords'"
      [focusFirst]="true"
      (selected)="handleSubmit($event)"
      [itemTemplate]="itemTemplate"
      [notFoundTemplate]="notFoundTemplate"
      [placeholder]="'Selecione'"
    >
    </ng-autocomplete>

    <ng-template #itemTemplate let-item>
      <div class="ms-2 p-2">
        <i class="fa fa-tractor" style="color: brown"></i>
        <strong> {{ item.name }}</strong
        ><br />
        <i class="fa fa-user" style="color: gray"></i>
        {{ item.farmerName }}<br />
      </div>
    </ng-template>

    <ng-template #notFoundTemplate let-notFound>
      <div [innerHTML]="'Produto não encontrado'"></div>
    </ng-template>
  </div>
</div>
