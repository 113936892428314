import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FoodGroup } from 'src/app/shared';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FoodGroupService {
  constructor(private apiService: ApiService) {}

  protected jsonDataToResources(jsonData: any[]): FoodGroup[] {
    const elements: FoodGroup[] = [];
    jsonData.forEach((element) =>
      elements.push(Object.assign(new FoodGroup(), element))
    );
    return elements;
  }

  protected jsonDataToResource(jsonData: any): FoodGroup {
    return Object.assign(new FoodGroup(), jsonData);
  }

  getAll(): Observable<FoodGroup[]> {
    return this.apiService
      .get('/grupo-alimentar')
      .pipe(map(this.jsonDataToResources));
  }

  getById(id: number): Observable<FoodGroup> {
    return this.apiService
      .get(`/grupo-alimentar/${id}`)
      .pipe(map(this.jsonDataToResource));
  }

  create(foodGroup: FoodGroup): Observable<FoodGroup> {
    return this.apiService
      .post('/grupo-alimentar', foodGroup)
      .pipe(map(this.jsonDataToResource));
  }

  update(foodGroup: FoodGroup): Observable<FoodGroup> {
    return this.apiService
      .put(`/grupo-alimentar/${foodGroup.id}`, foodGroup)
      .pipe(map(this.jsonDataToResource));
  }

  delete(foodGroup: FoodGroup): Observable<FoodGroup> {
    return this.apiService.delete(`/grupo-alimentar/${foodGroup.id}`);
  }
}
