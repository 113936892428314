import { Farm } from '.';
import {Payment} from './payment.model';
import {SaleItem} from './sale-item.model';

export class Sale {
  constructor(
    id?: number,
    customerId?: number,
    payments?: Payment[],
    priceTableId?: number,
    saleTypeId?: number,
    salesItems?: SaleItem[],
    farm?: Farm,
    total?: number
  ) {
    this.id = id;
    this.customerId = customerId;
    this.payments = payments;
    this.priceTableId = priceTableId;
    this.saleTypeId = saleTypeId;
    this.salesItems = salesItems;
    this.farm = farm;
    this.total = total;
  }

  id?: number;
  customerId?: number;
  payments?: Payment[];
  priceTableId?: number;
  saleTypeId?: number;
  salesItems?: SaleItem[];
  farm?: Farm;
  total?: number;
}
