import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Extract } from 'src/app/shared';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ExtractService {
  constructor(private apiService: ApiService) {}

  protected jsonDataToResource(jsonData: any): Extract {
    return Object.assign(new Extract(), jsonData);
  }

  searchWithFiltersById(
    pageNumber: number,
    filters: any,
    fundId: number
  ): Observable<any> {
    const params: any = { page: pageNumber, size: 15 };
    Object.assign(params, filters);
    return this.apiService.get(`/extrato-fundo/${fundId}`, params);
  }
}
