import {Address} from './address.model';

export class SaleAddress {
  constructor(address?: Address, instructions?: string) {
    this.address = address;
    this.instructions = instructions;
  }

  address?: Address;
  instructions?: string;
}
