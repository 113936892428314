import { FoodGroup, MeasurementUnity, FileMetadata} from ".";

export class ProductType{
  constructor(
    id?: number,
    productName?: string,
    orderPoint?: number,
    active?: boolean,
    minimumStockWeb?: number,
    measurementUnityId?: number,
    measurementUnity?: MeasurementUnity,
    foodGroupId?: number,
    foodGroup?: string,
    productImages?: FileMetadata[]
  ){
    this.id = id;
    this.productName = productName;
    this.orderPoint = orderPoint;
    this.active = active;
    this.minimumStockWeb = minimumStockWeb;
    this.measurementUnityId = measurementUnityId;
    this.measurementUnity = measurementUnity;
    this.foodGroupId = foodGroupId;
    this.foodGroup = foodGroup;
    this.productImages = productImages;
  }
  id?: number;
  productName?: string;
  orderPoint?: number;
  active?: boolean;
  minimumStockWeb?: number;
  measurementUnityId?: number;
  measurementUnity?: MeasurementUnity;
  foodGroupId?: number;
  foodGroup?: string;
  productImages?: FileMetadata[];
}
