import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MeasurementUnity } from 'src/app/shared';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MeasurementUnityService {
  constructor(private apiService: ApiService) {}

  protected jsonDataToResources(jsonData: any[]): MeasurementUnity[] {
    const elements: MeasurementUnity[] = [];
    jsonData.forEach((element) =>
      elements.push(Object.assign(new MeasurementUnity(), element))
    );
    return elements;
  }

  protected jsonDataToResource(jsonData: any): MeasurementUnity {
    return Object.assign(new MeasurementUnity(), jsonData);
  }

  getAll(): Observable<MeasurementUnity[]> {
    return this.apiService
      .get('/unidade-medida')
      .pipe(map(this.jsonDataToResources));
  }

  getById(id: number): Observable<MeasurementUnity> {
    return this.apiService
      .get(`/unidade-medida/${id}`)
      .pipe(map(this.jsonDataToResource));
  }

  create(measurementUnity: MeasurementUnity): Observable<MeasurementUnity> {
    return this.apiService
      .post('/unidade-medida', measurementUnity)
      .pipe(map(this.jsonDataToResource));
  }

  update(measurementUnity: MeasurementUnity): Observable<MeasurementUnity> {
    return this.apiService
      .put(`/unidade-medida/${measurementUnity.id}`, measurementUnity)
      .pipe(map(this.jsonDataToResource));
  }

  delete(measurementUnity: MeasurementUnity): Observable<MeasurementUnity> {
    return this.apiService
      .delete(`/unidade-medida/${measurementUnity.id}`)
      .pipe(map(this.jsonDataToResource));
  }
}
