import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FarmCommission } from 'src/app/shared';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FarmCommissionService {
  constructor(private apiService: ApiService) {}

  protected jsonDataToResources(jsonData: any[]): FarmCommission[] {
    const elements: FarmCommission[] = [];
    jsonData.forEach((element) =>
      elements.push(Object.assign(new FarmCommission(), element))
    );
    return elements;
  }

  protected jsonDataToResource(jsonData: any): FarmCommission {
    return Object.assign(new FarmCommission(), jsonData);
  }

  payFarmComission(farmId: number, fundId: number, untilDate?: string): Observable<FarmCommission> {
    return this.apiService
      .post(`/comissao/${farmId}?fundId=${fundId}&untilDate=${untilDate}`, {})
      .pipe(map(this.jsonDataToResource));
  }

  getByFarm(farmId: number): Observable<FarmCommission> {
    return this.apiService
      .get(`/comissao/${farmId}`)
      .pipe(map(this.jsonDataToResource));
  }

  getPendingByFarm(farmId: number, untilDate?: string): Observable<FarmCommission> {
    return this.apiService
      .get(`/comissao/em-aberto/${farmId}?untilDate=${untilDate}`)
      .pipe(map(this.jsonDataToResource));
  }

  searchWithFilters(pageNumber: number, filters: any[]): Observable<any> {
    const params: any = { page: pageNumber, size: 5 };
    Object.assign(params, filters);
    return this.apiService.get(`/comissao`, params);
  }

  delete(farmCommissionId: number): Observable<FarmCommission> {
    return this.apiService.delete(`/comissao/${farmCommissionId}`);
  }
}
