export class Payment {
  constructor(
    id?: number,
    amountPaid?: number,
    formOfPaymentId?: number,
    formOfPayment?: string
  ) {
    this.id = id;
    this.amountPaid = amountPaid;
    this.formOfPaymentId = formOfPaymentId;
    this.formOfPayment = formOfPayment;
  }

  id?: number;
  amountPaid?: number;
  formOfPaymentId?: number;
  formOfPayment?: string;
}
