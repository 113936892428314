import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PricesTable, PriceTableItem } from 'src/app/shared';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PricesTableService {
  constructor(private apiService: ApiService) {}

  protected jsonDataToResources(jsonData: any[]): PricesTable[] {
    const elements: PricesTable[] = [];
    jsonData.forEach((element) =>
      elements.push(Object.assign(new PricesTable(), element))
    );
    return elements;
  }

  protected jsonDataToResource(jsonData: any): PricesTable {
    const pricesTable: PricesTable = Object.assign(new PricesTable(), jsonData);
    if (pricesTable.items) {
      const products: PriceTableItem[] = [];
      pricesTable.items.forEach((item) =>
        products.push(Object.assign(new PriceTableItem(), item))
      );

      pricesTable.items = products;
    }
    return pricesTable;
  }

  getAll(): Observable<PricesTable[]> {
    return this.apiService
      .get('/tabela-preco')
      .pipe(map(this.jsonDataToResources));
  }

  getById(id: number): Observable<PricesTable> {
    return this.apiService
      .get(`/tabela-preco/${id}`)
      .pipe(map(this.jsonDataToResource));
  }

  create(pricesTable: PricesTable): Observable<PricesTable> {
    return this.apiService
      .post('/tabela-preco', pricesTable)
      .pipe(map(this.jsonDataToResource));
  }

  update(pricesTable: PricesTable): Observable<PricesTable> {
    return this.apiService
      .put(`/tabela-preco/${pricesTable.id}`, pricesTable)
      .pipe(map(this.jsonDataToResource));
  }

  delete(pricesTable: PricesTable): Observable<PricesTable> {
    return this.apiService
      .delete(`/tabela-preco/${pricesTable.id}`)
      .pipe(map(this.jsonDataToResource));
  }

  changeDefault(priceTableIdDefault: number): Observable<number> {
    return this.apiService.patch(
      `/variaveis-sistema/tabela-default/${priceTableIdDefault}`
    );
  }

  getDefault(): Observable<any> {
    return this.apiService
      .get(`/variaveis-sistema/tabela-default`)
      .pipe(map((data) => data));
  }

  toggleActive(pricesTable: PricesTable): Observable<PricesTable> {
    return this.apiService.patch(`/tabela-preco/ativo/${pricesTable.id}`);
  }

  copy(pricesTableId: number): Observable<PricesTable> {
    return this.apiService.post(`/tabela-preco/copiar?priceTableId=${pricesTableId}`);
  }
}
