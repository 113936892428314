import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileMetadata, ProductType } from 'src/app/shared';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductTypeService {
  constructor(private apiService: ApiService) {}

  protected jsonDataToResources(jsonData: any[]): ProductType[] {
    function jsonDataToResource(jsonData: any): ProductType {
      const productType: ProductType = Object.assign(
        new ProductType(),
        jsonData
      );
      if (productType.productImages) {
        productType.productImages = productType.productImages.map((image) =>
          Object.assign(new FileMetadata(), image)
        );
      }
      return productType;
    }
    return jsonData.map((element) => jsonDataToResource(element));
  }

  protected jsonDataToResource(jsonData: any): ProductType {
    const productType: ProductType = Object.assign(new ProductType(), jsonData);
    if (productType.productImages) {
      productType.productImages = productType.productImages.map((image) =>
        Object.assign(new FileMetadata(), image)
      );
    }
    return productType;
  }

  getAll(): Observable<ProductType[]> {
    return this.apiService
      .get('/tipo-produto')
      .pipe(map(this.jsonDataToResources));
  }

  getById(id: number): Observable<ProductType> {
    return this.apiService
      .get(`/tipo-produto/${id}`)
      .pipe(map(this.jsonDataToResource));
  }

  create(productType: ProductType): Observable<ProductType> {
    return this.apiService
      .post('/tipo-produto', productType)
      .pipe(map(this.jsonDataToResource));
  }

  update(productType: ProductType): Observable<ProductType> {
    return this.apiService
      .put(`/tipo-produto/${productType.id}`, productType)
      .pipe(map(this.jsonDataToResource));
  }

  delete(productType: ProductType): Observable<ProductType> {
    return this.apiService.delete(`/tipo-produto/${productType.id}`);
  }
}
