import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductEntry} from 'src/app/shared';

import {ApiService} from './api.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductEntryService {
  constructor(
    private apiService: ApiService
  ) {
  }

  protected jsonDataToResources(jsonData: any[]): ProductEntry[] {
    const elements: ProductEntry[] = [];
    jsonData.forEach((element) =>
      elements.push(Object.assign(new ProductEntry(), element))
    );
    return elements;
  }

  protected jsonDataToResource(jsonData: any): ProductEntry {
    return Object.assign(new ProductEntry(), jsonData);
  }

  getByFarm(farmId: number): Observable<ProductEntry[]> {
    return this.apiService.get(`/entrada-produto?farmId=${farmId}`)
      .pipe(map(this.jsonDataToResources));
  }

  searchWithFilters(pageNumber: number, filters: any[]): Observable<any> {
    const params: any = {page: pageNumber, size: 15};
    Object.assign(params, filters);
    return this.apiService.get(`/entrada-produto`, params);
  }

  create(productEntries: ProductEntry[]): Observable<{}> {
    return this.apiService
      .post(`/entrada-produto`, {
        productEntries
      })
      .pipe(map(this.jsonDataToResource));
  }

  delete(productEntryId: number): Observable<ProductEntry> {
    return this.apiService.delete(`/entrada-produto/${productEntryId}`);
  }
}
