import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-select-product-modal',
  templateUrl: './select-product-modal.component.html'
})
export class SelectProductModalComponent implements OnInit, AfterViewInit {
  @ViewChild('productName') productName: ElementRef | undefined;

  constructor(
    private activeModal: NgbActiveModal,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.productName?.nativeElement.focus();
  }

  actionsForError(): void {
    this.toastr.error('Erro ao carregar os produtos');
    this.activeModal.dismiss();
  }

  handleSubmit(productId: number): void {
    this.activeModal.close(productId);
  }

  handleCancel(): void {
    this.activeModal.dismiss();
  }

}
