import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/http/api.service';

@Injectable({
  providedIn: 'root',
})
export class ScaleService {
  constructor(private toastr: ToastrService, private apiService: ApiService) {}

  currentWeightFromUSB: number = 0;
  serialPort: any = null;
  ipAddress: string = null;
  webSocketConnection: WebSocket;
  connectionAlreadyTried: boolean = false;

  connectToScaleWEB(): Promise<void> {
    return new Promise(resolve => {
      const url = `wss://${this.ipAddress}`;
      this.webSocketConnection = new WebSocket(url);
      resolve();
    })
  }

  destroyWebSocketConnection(): void {
    this.webSocketConnection.close();
  }

  async connectToScaleViaUsb(): Promise<void> {
    if (!this.serialPort) {
      //@ts-ignore
      this.serialPort = await navigator.serial.requestPort();
    }
    await this.serialPort.open({
      baudRate: 9600,
      parity: 'none',
      stopBits: 1,
      dataBits: 8,
    });
    this.savePortInLocalStorage();
    this.refreshWeightByUSB();
  }

  saveIpOnLocalStorage(ipAddress: string): void {
    localStorage.setItem(
      'scale-device-network',
      JSON.stringify({
        ip: ipAddress,
      })
    );
  }

  deleteIpFromLocalStorage(): void {
    alert('removendo IP');
    localStorage.removeItem('scale-device-network');
  }

  savePortInLocalStorage(): void {
    if (this.isConnectedToUSB) {
      const infos = this.serialPort.getInfo();
      localStorage.setItem(
        'scale-device',
        JSON.stringify({
          usbProductId: infos.usbProductId,
          usbVendorId: infos.usbVendorId,
        })
      );
    }
  }

  getLocalStoragePersistence(): void {
    if (localStorage.getItem('scale-device-network')) {
      const scaleWEBDevice = JSON.parse(
        localStorage.getItem('scale-device-network')
      );
      this.ipAddress = scaleWEBDevice.ip;
      return;
    }

    if (localStorage.getItem('scale-device')) {
      const scaleDevice = JSON.parse(localStorage.getItem('scale-device'));
      //@ts-ignore
      navigator.serial.getPorts().then((ports) => {
        ports.forEach((port: any) => {
          const portInfo = port.getInfo();
          if (
            portInfo.usbProductId == scaleDevice.usbProductId &&
            portInfo.usbVendorId == scaleDevice.usbVendorId
          ) {
            this.serialPort = port;
            this.connectToScaleViaUsb();
          }
        });
      });
    }
  }

  async refreshWeightByUSB(): Promise<void> {
    function convertHexToNumber(hexArray: Uint8Array): number {
      return Number(new TextDecoder().decode(hexArray).replace(/[^0-9.]/g, ''));
    }
    while (this.serialPort.readable) {
      const reader = this.serialPort.readable.getReader();
      try {
        while (true) {
          const { value, done } = await reader.read();
          if (done) {
            break;
          }
          this.currentWeightFromUSB = convertHexToNumber(value);
        }
      } catch (error) {
        console.log('Erro ao fazer a leitura');
      } finally {
        reader.releaseLock();
      }
    }
  }

  get hasNavigatorSerial(): boolean {
    if (!navigator.hasOwnProperty('serial')) {
      return false;
    }
    return true;
  }

  get isConnectedToWEB(): boolean {
    return !!this.ipAddress;
  }

  get isConnectedToUSB(): boolean {
    if (this.serialPort) {
      if (this.serialPort.readable) {
        return true;
      }
    }
    return false;
  }

  getWeightWEB(): Promise<number> {
    return new Promise((resolve) => {
      this.webSocketConnection.send('getWeight');
      this.webSocketConnection.onmessage = (event) => {
        console.log(
          `Peso: ${Number(String(event.data).replace(/[^0-9.]/g, ''))}`
        );
        resolve(Number(String(event.data).replace(/[^0-9.]/g, '')));
      };
    });
  }
}
