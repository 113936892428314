import { AbstractControl, ValidatorFn } from '@angular/forms';

export function validateTelephone(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let telephone = control.value;
    if(!telephone) {
      return null;
    }
    telephone = telephone.replace(/\D+/g, '');

    if (telephone.length === 0) {
      return null;
    }

    if (telephone.length !== 10 && telephone.length !== 11) {
      return { telephone: true };
    }

    if (telephone.length === 11) {
      if (telephone[2] !== '9') {
        return { telephone: true };
      }
    }
    return null;
  };
}
