import {FormArray, FormControl, FormGroup} from '@angular/forms';

export class FormValidations {

  static requiredMinCheckbox(min = 1) {
    const validator = (formArray: FormArray) => {
      const totalChecked = formArray.controls
        .map(v => v.value)
        .reduce((total, current) => current ? total + current : total, 0);
      return totalChecked >= min ? null : {required: true};
    };
    return validator;
  }

  static requiredUFRG(fg: FormGroup) {
    const ufRG = fg.get('ufRG').value;
    const rg = fg.get('rg').value;
    return ((ufRG == null || ufRG == '') && (rg == null || rg == '')) || (ufRG != null || ufRG != '') && (rg != null || rg != '')
      ? null
      : {ufRG: true};
  }

  static cepValidator(control: FormControl) {
    const cep = control.value;
    if (cep && cep !== '') {
      const validacep = /^[0-9]{8}$/;
      return validacep.test(cep) ? null : {cepInvalido: true};
    }
    return null;
  }

  static equalsTo(otherField: string) {
    const validator = (formControl: FormControl) => {
      if (otherField == null) {
        throw new Error('É necessário informar um campo.');
      }

      if (!formControl.root || !(<FormGroup> formControl.root).controls) {
        return null;
      }

      const field = (<FormGroup> formControl.root).get(otherField);

      if (!field) {
        throw new Error('É necessário informar um campo válido.');
      }

      if (field.value !== formControl.value) {
        return {equalsTo: otherField};
      }

      return null;
    };
    return validator;
  }

  static getErrorMsg(fieldName: string, validatorName: string, validatorValue?: any): string {
    const config: any = {
      required: `${fieldName} é obrigatório.`,
      minlength: `Mínimo de ${validatorValue.requiredLength} caracteres.`,
      maxlength: `Máximo de ${validatorValue.requiredLength} caracteres.`,
      min: `O número deve ser maior que zero.`,
      cep: 'CEP inválido.',
      cpf: 'CPF inválido.',
      cnpj: 'CNPJ inválido.',
      email: 'E-mail inválido',
      telephone: 'Telefone inválido.',
      ufRG: 'RG precisa da UF de emissão',
      equalsTo: 'Campos não são iguais',
      pattern: 'Campo inválido',
      barcode: 'Código de Barras inválido',
      trim: 'Espaços em branco inválidos'
    };

    return config[validatorName];
  }
}
