<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="selectFarmModalTitle">
      Selecione a Propriedade
    </h5>
    <button
      type="button"
      class="btn-close shadow-none"
      (click)="handleCancel()"
      aria-label="Close"
      style="outline: none"
    ></button>
  </div>
  <div class="modal-body">
    <div class="m-2">
      <app-farm-select
        (farmError)="actionsForError()"
        (farmSelected)="handleSubmit($event)"
      ></app-farm-select>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="handleCancel()">
      Cancelar
    </button>
  </div>
</div>
