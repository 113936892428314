import {AbstractControl, ValidatorFn} from '@angular/forms';

export function validateCPF(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let cpf = control.value;
    cpf = cpf.replace(/\D+/g, '');

    if (cpf.length === 0) {
      return null;
    }
    if (cpf.length < 11) {
      return {cpf: true};
    }

    let sum;
    let rest;
    sum = 0;

    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    }
    rest = (sum * 10) % 11;

    if ((rest === 10) || (rest === 11)) {
      rest = 0;
    }
    if (rest !== parseInt(cpf.substring(9, 10), 10)) {
      return {cpf: true};
    }

    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    }
    rest = (sum * 10) % 11;

    if ((rest === 10) || (rest === 11)) {
      rest = 0;
    }
    if (rest !== parseInt(cpf.substring(10, 11), 10)) {
      return {cpf: true};
    }
    return null;
  };
}
