import {FileMetadata} from 'src/app/shared';
import {CertificateType} from 'src/app/shared';

export class Certification {
  constructor(id?: number, expirationDate?: string, fileMetadata?: FileMetadata, certificateType?: CertificateType, active?: boolean) {
    this.id = id;
    this.expirationDate = expirationDate;
    this.fileMetadata = fileMetadata;
    this.certificateType = certificateType;
    this.active = active;
  }

  id?: number;
  expirationDate?: string;
  fileMetadata?: FileMetadata;
  certificateType?: CertificateType;
  farmId?: number;
  active?: boolean;
}
