import {Injectable} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class MapService {
  map: mapboxgl.Map | undefined;
  style = 'mapbox://styles/mapbox/satellite-streets-v11';
  lat = -18.7084804;
  lng = -40.3993199;
  zoom = 13;

  constructor() {
  }

  buildMap(): void {
    this.map = new mapboxgl.Map({
      accessToken: environment.mapboxToken,
      container: 'map',
      style: this.style,
      zoom: this.zoom,
      center: [this.lng, this.lat],
      doubleClickZoom: false,
      attributionControl: false,
      pitchWithRotate: false
    });
    this.map.addControl(new mapboxgl.NavigationControl());
  }
}
