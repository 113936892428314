import { Address } from '.';

export class Supplier {
  id?: number;
  active?: boolean;
  personSupplier?: boolean;
  address?: Address;
  telephone?: string;
  name?: string;
  birthday?: string;
  cpf?: string;
  rg?: string;
  ufRG?: string;
  companyName?: string;
  tradingName?: string;
  inscricaoEstadual?: string;
  cnpj?: string;

  get keywords(): string {
    if (this.name) {
      return `${this.name}`;
    } else {
      return `${this.tradingName}`;
    }
  }
}
