import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  private static formatErrors(error: any): any {
    return throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams(), customURL?: string): Observable<any> {
    if(customURL) {
      return this.http
      .get(`${customURL}${path}`, { params })
      .pipe(catchError(ApiService.formatErrors));
    }
    return this.http
      .get(`${environment.api_url}${path}`, { params })
      .pipe(catchError(ApiService.formatErrors));
  }

  post(path: string, body: object = {}): Observable<any> {
    return this.http
      .post(`${environment.api_url}${path}`, body)
      .pipe(catchError(ApiService.formatErrors));
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http
      .put(`${environment.api_url}${path}`, body)
      .pipe(catchError(ApiService.formatErrors));
  }

  patch(path: string, body: object = {}): Observable<any> {
    return this.http
      .patch(`${environment.api_url}${path}`, body)
      .pipe(catchError(ApiService.formatErrors));
  }

  delete(path: string): Observable<any> {
    return this.http
      .delete(`${environment.api_url}${path}`)
      .pipe(catchError(ApiService.formatErrors));
  }
}
