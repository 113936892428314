export class FormOfPayment{
  constructor(id?: number, description?: string, fundFinancialId?: number){
    this.id = id;
    this.description = description;
    this.fundFinancialId = fundFinancialId;
  }

  id?: number;
  description?: string;
  fundFinancialId?: number;

  get keywords(): string {
    return `${this.id} ${this.description}`
  }
}
