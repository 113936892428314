import { BalanceStatement } from './balance-statement.model';

export class Extract {
  constructor(
    totalItems?: number,
    balanceInitial?: number,
    currentBalance?: number,
    totalPages?: string,
    balanceStatement?: BalanceStatement[]
  ) {
    this.totalItems = totalItems;
    this.balanceInitial = balanceInitial;
    this.currentBalance = currentBalance;
    this.totalPages = totalPages;
    this.balanceStatement = balanceStatement;
  }

  totalItems?: number;
  balanceInitial?: number;
  currentBalance?: number;
  totalPages?: string;
  balanceStatement?: BalanceStatement[];
}
