export class BalanceStatement {
  constructor(
    id?: number,
    typeTransaction?: string,
    description?: string,
    date?: string,
    action?: string,
    amount?: number
  ) {
    this.id = id;
    this.typeTransaction = typeTransaction;
    this.description = description;
    this.date = date;
    this.action = action;
    this.amount = amount;
  }

  id?: number;
  typeTransaction?: string;
  description?: string;
  date?: string;
  action?: string;
  amount?: number;
}
