import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

import { FileMetadata } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private apiService: ApiService) {}

  protected jsonDataToResources(jsonData: any[]): FileMetadata[] {
    const elements: FileMetadata[] = [];
    jsonData.forEach((element) =>
      elements.push(Object.assign(new FileMetadata(), element))
    );
    return elements;
  }

  protected jsonDataToResource(jsonData: any): FileMetadata {
    return Object.assign(new FileMetadata(), jsonData);
  }

  uploadFile(file: File): Observable<FileMetadata> {
    const formData = new FormData();
    formData.append('file', file);

    return this.apiService
      .post('/file-storage/upload', formData)
      .pipe(map(this.jsonDataToResource));
  }

  deleteFile(fileMetaDataId: number): Observable<void> {
    return this.apiService
      .delete(`/file-storage/delete/${fileMetaDataId}`)
      .pipe(map(() => {}));
  }

  uploadMultipleFiles(files: File[]): Observable<FileMetadata[]> {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }

    return this.apiService
      .post(`/upload-multiple-files`, formData)
      .pipe(map(this.jsonDataToResources));
  }
}
