import { Injectable } from '@angular/core';

interface Masks {
  cpf: string;
  cep: string;
  telephone: string;
  cnpj: string;
  barcode: string;
  ip: string;
}

@Injectable({
  providedIn: 'root',
})
export class BrazilMasksService {
  public get masks(): Masks {
    return {
      cpf: '000.000.000-00',
      cep: '00.000-000',
      telephone: '(00) 0000-0000||(00) 00000-0000',
      cnpj: '00.000.000/0000-00',
      barcode: '000 0000 000000',
      ip: '990.990.990.990',
    };
  }
}
