import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Fund } from 'src/app/shared';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FundService {
  constructor(private apiService: ApiService) {}

  protected jsonDataToResources(jsonData: any[]): Fund[] {
    const elements: Fund[] = [];
    jsonData.forEach((element) =>
      elements.push(Object.assign(new Fund(), element))
    );
    return elements;
  }

  protected jsonDataToResource(jsonData: any): Fund {
    return Object.assign(new Fund(), jsonData);
  }

  getAll(): Observable<Fund[]> {
    return this.apiService
      .get('/fundo/todos')
      .pipe(map(this.jsonDataToResources));
  }

  getById(id: number): Observable<Fund> {
    return this.apiService
      .get(`/fundo/${id}`)
      .pipe(map(this.jsonDataToResource));
  }

  create(fund: Fund): Observable<Fund> {
    return this.apiService
      .post('/fundo', fund)
      .pipe(map(this.jsonDataToResource));
  }

  update(fund: Fund): Observable<Fund> {
    return this.apiService
      .put(`/fundo/${fund.id}`, fund)
      .pipe(map(this.jsonDataToResource));
  }

  delete(fund: Fund): Observable<Fund> {
    return this.apiService.delete(`/fundo/${fund.id}`);
  }
}
