<div [formGroup]="address">
  <div class="row">
    <div class="col-lg-3 mb-2">
      <label for="cep" class="form-label">CEP</label>
      <input
        id="cep"
        formControlName="cep"
        type="text"
        (blur)="findAddressByCEP()"
        maxLength="50"
        class="form-control"
        [mask]="cepMask"
        autocomplete="off"
      />

      <app-form-error-msg
        [control]="address?.get('cep')"
        label="CEP"
        [attemptedRegistration]="attemptedRegistration"
      ></app-form-error-msg>
    </div>

    <div class="col-lg-2 mb-2">
      <label for="state" class="form-label">UF</label>
      <select id="state" formControlName="state" class="form-control">
        <option [selected]="true" value="" disabled>Selecione</option>
        <option *ngFor="let state of states">
          {{ state }}
        </option>
      </select>

      <app-form-error-msg
        [control]="address?.get('state')"
        label="Estado"
        [attemptedRegistration]="attemptedRegistration"
      ></app-form-error-msg>
    </div>

    <div class="col-lg-5 mb-2">
      <label for="city" class="form-label">Cidade</label>
      <input
        id="city"
        formControlName="city"
        type="text"
        maxLength="50"
        class="form-control"
        autocomplete="off"
      />

      <app-form-error-msg
        [control]="address?.get('city')"
        label="Cidade"
        [attemptedRegistration]="attemptedRegistration"
      ></app-form-error-msg>
    </div>

    <div class="col-lg-2 mb-2">
      <label for="houseNumber" class="form-label">N°</label>
      <input
        id="houseNumber"
        formControlName="houseNumber"
        type="text"
        maxLength="8"
        class="form-control"
        autocomplete="off"
        OnlyNumber
      />
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-lg-4 mb-2">
      <label for="neighborhood" class="form-label">Bairro</label>
      <input
        id="neighborhood"
        formControlName="neighborhood"
        type="text"
        maxLength="50"
        class="form-control"
        autocomplete="off"
      />
    </div>

    <div class="col-lg-4 mb-2">
      <label for="street" class="form-label">Logradouro</label>
      <input
        id="street"
        formControlName="street"
        type="text"
        maxLength="50"
        class="form-control"
        autocomplete="off"
      />
    </div>

    <div class="col-lg-4 mb-2">
      <label for="detail" class="form-label">Complemento</label>
      <input
        id="detail"
        formControlName="detail"
        type="text"
        maxLength="50"
        class="form-control"
        autocomplete="off"
      />
    </div>
  </div>
</div>
