import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { UtilsService } from '..';

@Directive({
  selector: '[OnlyNumber]',
})
export class OnlyNumbersDirective {
  private navigationKeys: string[] = [
    'Backspace',
    'Delete',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
    'Tab'
  ];

  inputElement: HTMLElement;
  constructor(public el: ElementRef, private utils: UtilsService) {
    this.inputElement = el.nativeElement;
  }

  private isNumber(char: any): boolean {
    if (typeof char !== 'string') {
      return false;
    }
  
    if (char.trim() === '') {
      return false;
    }
    return !isNaN(Number(char));
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Allow: Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Allow: Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Allow: Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      return;
    }
    if (!this.isNumber(e.key)) {
      e.preventDefault();
    }
  }
  
  @HostListener('keyup', ['$event'])
  onChange(event: ClipboardEvent) {
    
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
  }
}
