import {Component, OnInit, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Address, BrazilianStatesService, BrazilMasksService, validateCep} from 'src/app/shared';
import {BrasilApiService, UtilsService} from 'src/app/shared';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.css']
})
export class AddressFormComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() isRequired: boolean;
  @Input() attemptedRegistration: boolean = false;

  states: string[] = [];

  address: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private brazilianStates: BrazilianStatesService,
    public utils: UtilsService,
    private brasilApi: BrasilApiService,
    private brazilMasksService: BrazilMasksService
  ) {
    this.states = brazilianStates.getStates();

    this.address = this.formBuilder.group({
      id: [''],
      cep: [''],
      state: [''],
      city: [''],
      houseNumber: [''],
      neighborhood: [''],
      street: [''],
      detail: [''],
    });
  }

  ngOnInit(): void {
    this.parentForm.addControl('address', this.address);
    this.verifyIfRequired();
  }

  private verifyIfRequired(): void {
    if (this.isRequired) {
      this.address.controls.cep.setValidators([Validators.required, validateCep()]);
      this.address.controls.state.setValidators(Validators.required);
      this.address.controls.city.setValidators(Validators.required);
    }
  }

  findAddressByCEP(): void {
    if (this.address.get('cep').invalid || this.address.get('cep').value == null) {
      return;
    }

    let cepToFind = this.address.value.cep;
    cepToFind = cepToFind.replace(/[^\d]+/g, '');
    this.brasilApi.getAddressByCEP(cepToFind).subscribe(
      address => this.updateAdress(address),
      error => console.log('Erro no servico do Brasil api')
    );
  }

  updateAdress(address: Address): void {
    this.address.patchValue(address);
  }
  get telephoneMask(): string {
    return this.brazilMasksService.masks.telephone;
  }

  get cepMask(): string {
    return this.brazilMasksService.masks.cep;
  }
}
