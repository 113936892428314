import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {FarmProductService} from 'src/app/core';
import {FarmProduct} from '../../models';

@Component({
  selector: 'app-farm-products-select',
  templateUrl: './farm-products-select.component.html',
  styleUrls: ['./farm-products-select.component.css']
})
export class FarmProductsSelectComponent implements OnInit, OnChanges {

  @Input() farmId: number;
  @Input() parentForm: FormGroup;

  farmProductsArray: FarmProduct[];

  farmProduct: FormControl;

  constructor(
    private farmProductService: FarmProductService,
    private toastr: ToastrService
  ) {
    this.farmProduct = new FormControl('', Validators.required);
  }

  ngOnInit(): void {
    this.parentForm.addControl('farmProduct', this.farmProduct);
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'farmId' && this.farmId != null) {
        this.getFarmProductsFromDatabase();
      }
    }
  }

  private getFarmProductsFromDatabase(): void {
    this.farmProductService.getByFarm(this.farmId)
      .subscribe(
        farmProductsArray => this.farmProductsArray = farmProductsArray,
        () => this.toastr.error('Erro ao carregar os produtos da propriedade')
      );
  }

}
