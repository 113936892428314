import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ProductTypeService} from 'src/app/core';
import {ProductType} from '../../models';

@Component({
  selector: 'app-product-select',
  templateUrl: './product-select.component.html',
  styleUrls: ['./product-select.component.css']
})
export class ProductSelectComponent implements OnInit, AfterViewInit {

  @Output() error = new EventEmitter<string>();
  @Output() productSelected = new EventEmitter<number>();

  productsArray: ProductType[];
  productNameInput: FormControl;
  showDropDown = false;

  constructor(
    private productsService: ProductTypeService,
  ) {
    this.productNameInput = new FormControl('');
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.getAllProductsFromDatabase();
  }

  private getAllProductsFromDatabase(): void {
    this.productsService.getAll()
      .subscribe(
        productsArray => this.productsArray = productsArray,
        () => this.actionsForError()
      );
  }

  closeDropDown(): void {
    this.showDropDown = !this.showDropDown;
  }

  openDropDown(): void {
    this.showDropDown = false;
  }

  private actionsForError(): void {
    this.error.emit('Erro ao carregar os produtos');
  }

  handleSubmit(product: ProductType): void {
    this.productSelected.emit(product.id);
  }

  getSearchValue(): string {
    return this.productNameInput.value;
  }

  get filteredProducts(): ProductType[] {
    return this.productsArray.filter(
      (product) =>
        product.productName
          .toLocaleLowerCase()
          .indexOf(this.getSearchValue().toLowerCase()) !== -1
    );
  }

}
