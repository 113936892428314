import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormValidations } from './form-validations';

@Component({
  selector: 'app-form-error-msg',
  templateUrl: './form-error-msg.component.html',
  styleUrls: ['./form-error-msg.component.css'],
})
export class FormErrorMsgComponent implements OnInit {
  @Input() control: FormControl;
  @Input() label: string;
  @Input() attemptedRegistration: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  get errorMessage(): string {
    for (const propertyName in this.control?.errors) {
      if (
        this.control.errors.hasOwnProperty(propertyName) &&
        (this.control.touched || this.attemptedRegistration)
      ) {
        return FormValidations.getErrorMsg(
          this.label,
          propertyName,
          this.control.errors[propertyName]
        );
      }
    }

    return null;
  }
}
