import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Product} from 'src/app/modules/stock/stock-query/models/product';

import {ApiService} from './api.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StockQueryService {
  constructor(
    private apiService: ApiService
  ) {
  }

  protected jsonDataToResources(jsonData: any[]): Product[] {
    const elements: Product[] = [];
    jsonData.forEach((element) =>
      elements.push(Object.assign(new Product(), element))
    );
    return elements;
  }

  protected jsonDataToResource(jsonData: any): Product {
    return Object.assign(new Product(), jsonData);
  }

  getByFarmId(farmId: number): Observable<Product[]> {
    return this.apiService.get(`/estoque/propriedade/${farmId}`)
      .pipe(map(this.jsonDataToResources));
  }

  getByProductTypeId(productTypeId: number): Observable<Product[]> {
    return this.apiService.get(`/estoque/produto/${productTypeId}`)
      .pipe(map(this.jsonDataToResources));
  }

  getAllProductsStock(): Observable<Product[]> {
    return this.apiService.get(`/estoque`)
      .pipe(map(this.jsonDataToResources));
  }
}
