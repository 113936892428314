import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CertificateType } from 'src/app/shared';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CertificateTypeService {
  constructor(private apiService: ApiService) {}

  protected jsonDataToResources(jsonData: any[]): CertificateType[] {
    const elements: CertificateType[] = [];
    jsonData.forEach((element) =>
      elements.push(Object.assign(new CertificateType(), element))
    );
    return elements;
  }

  protected jsonDataToResource(jsonData: any): CertificateType {
    return Object.assign(new CertificateType(), jsonData);
  }

  getAll(): Observable<CertificateType[]> {
    return this.apiService
      .get(`/tipo-certificado`)
      .pipe(map(this.jsonDataToResources));
  }

  create(certificateType: CertificateType): Observable<CertificateType> {
    return this.apiService
      .post('/tipo-certificado', certificateType)
      .pipe(map(this.jsonDataToResource));
  }

  update(certificateType: CertificateType): Observable<CertificateType> {
    return this.apiService
      .put('/tipo-certificado', certificateType)
      .pipe(map(this.jsonDataToResource));
  }

  delete(certificateTypeId: number): Observable<CertificateType> {
    return this.apiService.delete(`/tipo-certificado/${certificateTypeId}`);
  }
}
