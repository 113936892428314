<div class="d-flex justify-content-center">
  <div class="ng-autocomplete">
    <label>Produto</label>
    <ng-autocomplete
      [data]="productsArray"
      [searchKeyword]="'productName'"
      [focusFirst]="true"
      (selected)="handleSubmit($event)"
      [itemTemplate]="itemTemplate"
      [notFoundTemplate]="notFoundTemplate"
      [placeholder]="'Selecione'"
    >
    </ng-autocomplete>

    <ng-template #itemTemplate let-item>
      <div class="ms-2 p-2">
        <i class="fa fa-seedling" style="color: green; margin-right: 2px"></i>
        <span [innerHTML]="item.productName"></span>
      </div>
    </ng-template>

    <ng-template #notFoundTemplate let-notFound>
      <div [innerHTML]="'Produto não encontrado'"></div>
    </ng-template>
  </div>
</div>
