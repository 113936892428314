<nav
  id="sidebar"
  [ngClass]="{
    'hidden-sidebar': getSideBarState(),
    sidebar: !getSideBarState()
  }"
  (mouseenter)="openSidebar()"
  (mouseleave)="closeSidebar()"
>
  <div class="sidebar-content" *ngIf="!getSideBarState()">
    <perfect-scrollbar>
      <div class="sidebar-header">
        <a routerLink="/">universo orgâncio</a>
      </div>

      <div class="sidebar-profile">
        <div class="user-pic">
          <img
            class="img-responsive img-rounded"
            src="assets/logo.png"
            alt="User picture"
          />
        </div>
        <div class="user-info">
          <span class="user-name"> Administrator </span>
          <span class="user-role">Olá {{ userName }}</span>
          <span class="user-status">
            <i class="fa fa-circle"></i>
            <span>Online</span>
          </span>
        </div>
      </div>
      <div class="sidebar-menu">
        <ul>
          <li
            *ngFor="let menu of menus"
            [ngClass]="{
              active: menu.active,
              'sidebar-dropdown': menu.type === 'dropdown',
              'header-menu': menu.type === 'header'
            }"
          >
            <span *ngIf="menu.type === 'header'">{{ menu.title }}</span>
            <a *ngIf="menu.type === 'dropdown'" (click)="toggle(menu)">
              <i class="{{ menu.icon }}"></i>
              <span>{{ menu.title }}</span>
              <span
                *ngIf="menu.badge"
                class="badge badge-pill"
                [ngClass]="menu.badge.class"
                >{{ menu.badge.text }}</span
              >
            </a>
            <a *ngIf="menu.type === 'router'" [routerLink]="menu.router">
              <i class="{{ menu.icon }}"></i>
              <span>{{ menu.title }}</span>
              <span
                [@inOutAnimation]
                *ngIf="menu.badge"
                class="badge badge-pill"
                [ngClass]="menu.badge.class"
                >{{ menu.badge.text }}</span
              >
            </a>
            <div
              *ngIf="menu.type === 'dropdown'"
              class="sidebar-submenu"
              [@slide]="getState(menu)"
            >
              <ul>
                <li *ngFor="let submenu of menu.submenus">
                  <a [routerLink]="submenu.router">
                    {{ submenu.title }}
                    <span
                      *ngIf="submenu.badge"
                      class="badge badge-pill"
                      [ngClass]="submenu.badge.class"
                      >{{ submenu.badge.text }}</span
                    >
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </perfect-scrollbar>
  </div>
  <div class="sidebar-footer" *ngIf="!getSideBarState()">
    <div>
      <a routerLink="/configuracoes">
        <i class="fa fa-cog"></i>
      </a>
    </div>
    <div>
      <button (click)="handleLogout()">
        <i class="fa fa-sign-out-alt"></i>
      </button>
    </div>
  </div>

  <div
    class="hidden-sidebar-container"
    [ngClass]="{ 'hidden-sidebar-container-active': getSideBarState() }"
    *ngIf="getSideBarState()"
    (click)="openSidebar()"
  >
    <img
      [@inOutAnimation]
      class="img-responsive img-rounded"
      src="assets/logo.png"
      alt="User picture"
    />
    <div class="modules-container">
      <i [@inOutAnimation] class="fa fa-map-marked-alt"></i>
      <i [@inOutAnimation] class="fa fa-box-open"></i>
      <i [@inOutAnimation] class="fa fa-dollar-sign"></i>
      <i [@inOutAnimation] class="fa fa-money-check-alt"></i>
    </div>
    <div [@inOutAnimation] class="hidden-sidebar-footer">
      <div>
        <button (click)="handleLogout()">
          <i class="fa fa-sign-out-alt"></i>
        </button>
      </div>
    </div>
  </div>
</nav>
