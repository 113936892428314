import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { HeaderComponent, SelectFarmModalComponent } from './components';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { FormErrorMsgComponent } from './components/form-error-msg/form-error-msg.component';

import {
  FileBoxDirective,
  ClickOutsideDirective,
  OnlyNumbersDirective,
} from './directives';

import { InputFileBoxComponent } from './components/input-file-box/file.type';
import { MapBoxComponent } from './components/map-box/map-box.component';
import { FarmSelectComponent } from './components/farm-select/farm-select.component';
import { FarmProductsSelectComponent } from './components/farm-products-select/farm-products-select.component';
import { ProductSelectComponent } from './components/product-select/product-select.component';
import { SelectProductModalComponent } from './components/select-product-modal/select-product-modal.component';
import { ListItemsComponent } from './components/list-items/list-items.component';

import { NgxCurrencyModule } from 'ngx-currency';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxMaskModule } from 'ngx-mask';
import { SimpleLoaderComponent } from './components/simple-loader/simple-loader.component';

@NgModule({
  declarations: [
    HeaderComponent,
    AddressFormComponent,
    FormErrorMsgComponent,
    InputFileBoxComponent,
    MapBoxComponent,
    FarmSelectComponent,
    FarmProductsSelectComponent,
    ProductSelectComponent,
    SelectFarmModalComponent,
    SelectProductModalComponent,
    ListItemsComponent,
    FileBoxDirective,
    ClickOutsideDirective,
    OnlyNumbersDirective,
    SimpleLoaderComponent,
  ],

  imports: [
    CommonModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    NgxMaskModule,
  ],

  exports: [
    ReactiveFormsModule,
    NgxPaginationModule,
    NgbModalModule,
    NgxCurrencyModule,
    HeaderComponent,
    FormErrorMsgComponent,
    AddressFormComponent,
    InputFileBoxComponent,
    MapBoxComponent,
    FarmSelectComponent,
    FarmProductsSelectComponent,
    ProductSelectComponent,
    SelectProductModalComponent,
    ListItemsComponent,
    SimpleLoaderComponent,
    FileBoxDirective,
    ClickOutsideDirective,
    OnlyNumbersDirective,
  ],

  entryComponents: [SelectFarmModalComponent, SelectProductModalComponent],
})
export class SharedModule {}
