import { environment } from "src/environments/environment";

export class FileMetadata {
  id?: number;
  fileName?: string;
  fileDownloadUri?: string;
  fileType?: string;
  size?: number;
  fileId?: number;
  
  get downloadUri(): string {
    return `${environment.api_url}${this.fileDownloadUri}`;
  }
}
