import { AbstractControl, ValidatorFn } from '@angular/forms';

export function validatebarcode(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let barcode = control.value;
    if (!barcode) {
      return null;
    }
    if (barcode.length === 0) {
      return null;
    }

    if (barcode.length !== 13) {
      return { barcode: true };
    }

    return null;
  };
}
