import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root',
})
export class TraceabilityService {
  constructor(
    private apiService: ApiService
  ) {
  }

  getTraceabilityPage(farmId: number): Observable<any> {
    return this.apiService
      .get(`/rastreabilidade/${farmId}`)
      .pipe(map(data => {
        return data;
      }));
  }
}
