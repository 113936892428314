import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

import {FarmService} from 'src/app/core';
import {Farm} from '../../models';

@Component({
  selector: 'app-farm-select',
  templateUrl: './farm-select.component.html',
  styleUrls: ['./farm-select.component.css']
})
export class FarmSelectComponent implements OnInit, AfterViewInit {

  @Output() farmError = new EventEmitter<string>();
  @Output() farmSelected = new EventEmitter<Farm>();

  farmArray: Farm[];
  farmNameInput: FormControl;

  constructor(
    private farmService: FarmService,
  ) {
    this.farmNameInput = new FormControl('');
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.getAllFarmsFromDatabase();
  }

  private getAllFarmsFromDatabase(): void {
    this.farmService.getAllForSelect()
      .subscribe(
        farmArray => {
          this.farmArray = farmArray;
        },
        () => this.actionsForError()
      );
  }

  private actionsForError(): void {
    this.farmError.emit('Erro ao carregar os produtos');
  }

  handleSubmit(farm: Farm): void {
    this.farmSelected.emit(farm);
  }

}
