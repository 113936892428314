import { Component } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { SidebarService } from './sidebar.service';
import { AccountService } from '..';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  animations: [
    trigger('slide', [
      state('up', style({ height: 0 })),
      state('down', style({ height: '*' })),
      transition('up <=> down', animate(200)),
    ]),
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ paddingLeft: 240, opacity: 0 }),
        animate('0.3s ease-out', style({ paddingLeft: 0, opacity: 1 })),
      ]),
      transition(':leave', [
        style({ paddingLeft: 0, opacity: 1 }),
        animate('0.3s ease-in', style({ paddingLeft: 240, opacity: 0 })),
      ]),
    ]),
  ],
})
export class SidebarComponent {
  menus: any = [];

  constructor(
    public sidebarservice: SidebarService,
    public accountService: AccountService
  ) {
    this.menus = sidebarservice.getMenuList();
  }

  closeSidebar(): void {
    this.sidebarservice.close();
  }

  openSidebar(): void {
    this.sidebarservice.open();
  }

  getSideBarState(): any {
    return this.sidebarservice.getSidebarState();
  }

  toggle(currentMenu: any): void {
    if (currentMenu.type === 'dropdown') {
      this.menus.forEach((element: any) => {
        if (element === currentMenu) {
          currentMenu.active = !currentMenu.active;
        } else {
          element.active = false;
        }
      });
    }
  }

  getState(currentMenu: any): string {
    if (currentMenu.active) {
      return 'down';
    } else {
      return 'up';
    }
  }

  hasBackgroundImage(): boolean {
    return this.sidebarservice.hasBackgroundImage;
  }

  handleLogout(): void {
    this.accountService.logout();
  }

  get userName(): string {
    if (this.accountService.userValue) {
      return this.accountService.userValue.login;
    } else {
      return '';
    }
  }
}
