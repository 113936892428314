export class Address {
  constructor(
    id?: number,
    cep?: string,
    city?: string,
    state?: string,
    street?: string,
    neighborhood?: string,
    houseNumber?: string,
    detail?: string,
  ) {
    this.id = id;
    this.cep = cep;
    this.city = city;
    this.state = state;
    this.street = street;
    this.neighborhood = neighborhood;
    this.houseNumber = houseNumber;
    this.detail = detail;
  }

  id?: number;
  cep?: string;
  city?: string;
  state?: string;
  street?: string;
  neighborhood?: string;
  houseNumber?: string;
  detail?: string;
}
