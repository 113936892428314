import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Loan } from 'src/app/shared';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoanService {
  constructor(private apiService: ApiService) {}

  protected jsonDataToResources(jsonData: any[]): Loan[] {
    const elements: Loan[] = [];
    jsonData.forEach((element) =>
      elements.push(Object.assign(new Loan(), element))
    );
    return elements;
  }

  protected jsonDataToResource(jsonData: any): Loan {
    return Object.assign(new Loan(), jsonData);
  }

  getAll(): Observable<Loan[]> {
    return this.apiService
      .get('/emprestimo')
      .pipe(map(this.jsonDataToResources));
  }

  getById(id: number): Observable<Loan> {
    return this.apiService
      .get(`/emprestimo/${id}`)
      .pipe(map(this.jsonDataToResource));
  }

  create(loan: Loan): Observable<Loan> {
    return this.apiService
      .post('/emprestimo', loan)
      .pipe(map(this.jsonDataToResource));
  }

  pay(loanId: number, fundId: number): Observable<Loan> {
    return this.apiService
      .post(`/emprestimo/${loanId}?reimbursedFundId=${fundId}`, {})
      .pipe(map(this.jsonDataToResource));
  }

  update(loan: Loan): Observable<Loan> {
    return this.apiService
      .put(`/emprestimo/${loan.id}`, loan)
      .pipe(map(this.jsonDataToResource));
  }

  delete(loanId: number): Observable<Loan> {
    return this.apiService.delete(`/emprestimo/${loanId}`);
  }
}
