export class Fund {
  constructor(id?: number, name?: string, balance?: number, active?: boolean) {
    this.id = id;
    this.name = name;
    this.balance = balance;
    this.active = active;
  }
  
  id?: number;
  name?: string;
  balance?: number;
  active?: boolean;

  get keywords(): string {
    return `${this.name}`;
  }
}
