export class User {
  constructor(
    id?: number,
    userName?: string,
    login?: string,
    password?: string,
    token?: string
  ) {
    this.id = id;
    this.userName = userName;
    this.login = login;
    this.password = password;
    this.token = token;
  }
  id?: number;
  userName?: string;
  login?: string;
  password?: string;
  token?: string;
}
