import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FarmProduct } from 'src/app/shared';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FarmProductService {
  constructor(private apiService: ApiService) {}

  protected jsonDataToResources(jsonData: any[]): FarmProduct[] {
    const elements: FarmProduct[] = [];
    jsonData.forEach((element) =>
      elements.push(Object.assign(new FarmProduct(), element))
    );
    return elements;
  }

  protected jsonDataToResource(jsonData: any): FarmProduct {
    return Object.assign(new FarmProduct(), jsonData);
  }

  getAll(): Observable<FarmProduct[]> {
    return this.apiService
      .get(`/produtos-propriedade/tabela`)
      .pipe(map(this.jsonDataToResources));
  }

  getByFarm(farmId: number): Observable<FarmProduct[]> {
    return this.apiService
      .get(`/produtos-propriedade/propriedade/ativos/${farmId}`)
      .pipe(map(this.jsonDataToResources));
  }

  getById(id: number): Observable<FarmProduct> {
    return this.apiService
      .get(`/produtos-propriedade/${id}`)
      .pipe(map(this.jsonDataToResource));
  }

  create(product: FarmProduct): Observable<FarmProduct> {
    return this.apiService
      .post(`/produtos-propriedade/produto`, {
        productTypeId: product.productTypeId,
        farmId: product.farmId,
        organic: product.organic,
      })
      .pipe(map(this.jsonDataToResource));
  }

  createMultiple(
    farmId: number,
    products: FarmProduct[]
  ): Observable<FarmProduct[]> {
    return this.apiService
      .post(`/produtos-propriedade`, {
        farmId,
        products,
      })
      .pipe(map(this.jsonDataToResources));
  }

  update(farmProduct: FarmProduct): Observable<FarmProduct> {
    return this.apiService
      .put(`/produtos-propriedade/${farmProduct.id}`, {
        farmProduct,
      })
      .pipe(map(this.jsonDataToResource));
  }

  updateBarcode(
    farmProductId: number,
    barcode: string
  ): Observable<FarmProduct> {
    return this.apiService
      .patch(`/produtos-propriedade/codigo-barras/${farmProductId}?barCode=${barcode}`)
      .pipe(map(this.jsonDataToResource));
  }

  toggleActive(farmProductId: number): Observable<FarmProduct> {
    return this.apiService.patch(
      `/produtos-propriedade/ativo/${farmProductId}`
    );
  }
}
