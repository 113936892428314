import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TransferFundsModel} from 'src/app/shared';

import {ApiService} from './api.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TransferBetweenFundsService {
  constructor(
    private apiService: ApiService
  ) {
  }

  protected jsonDataToResources(jsonData: any[]): TransferFundsModel[] {
    const elements: TransferFundsModel[] = [];
    jsonData.forEach((element) =>
      elements.push(Object.assign(new TransferFundsModel(), element))
    );
    return elements;
  }

  protected jsonDataToResource(jsonData: any): TransferFundsModel {
    return Object.assign(new TransferFundsModel(), jsonData);
  }

  searchWithFilters(pageNumber: number, filters: any[]): Observable<any> {
    const params: any = {page: pageNumber, size: 15};
    Object.assign(params, filters);
    return this.apiService.get(`/movimentacao-fundos`, params);
  }

  getAll(): Observable<TransferFundsModel[]> {
    return this.apiService.get('/movimentacao-fundos')
      .pipe(map(this.jsonDataToResources));
  }

  create(tranfer: TransferFundsModel): Observable<TransferFundsModel> {
    return this.apiService
      .post('/movimentacao-fundos', tranfer)
      .pipe(map(this.jsonDataToResource));
  }

  delete(tranferId: number): Observable<TransferFundsModel> {
    return this.apiService.delete(`/movimentacao-fundos/${tranferId}`);
  }
}
