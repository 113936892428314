import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Certification, FileMetadata } from 'src/app/shared';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CertificationService {
  constructor(private apiService: ApiService) {}

  protected jsonDataToResources(jsonData: any[]): Certification[] {
    return jsonData.map(element => {
      const certification: Certification = Object.assign(
        new Certification(),
        element
      );
      if (certification.fileMetadata) {
        certification.fileMetadata = Object.assign(
          new FileMetadata(),
          certification.fileMetadata
        );
      }
      return certification;
    });
  }

  protected jsonDataToResource(jsonData: any): Certification {
    const certification: Certification = Object.assign(
      new Certification(),
      jsonData
    );
    if (certification.fileMetadata) {
      certification.fileMetadata = Object.assign(
        new FileMetadata(),
        certification.fileMetadata
      );
    }
    return certification;
  }

  getAll(farmId: number): Observable<Certification[]> {
    return this.apiService
      .get(`/certificado/sitio/${farmId}`)
      .pipe(map(this.jsonDataToResources));
  }

  getById(certificationId: number): Observable<Certification> {
    return this.apiService
      .get(`/certificado/${certificationId}`)
      .pipe(map(this.jsonDataToResource));
  }

  create(certification: Certification): Observable<Certification> {
    return this.apiService
      .post('/certificado', certification)
      .pipe(map(this.jsonDataToResource));
  }

  update(certification: Certification): Observable<Certification> {
    return this.apiService
      .put(`/certificado/${certification.id}`, certification)
      .pipe(map(this.jsonDataToResource));
  }

  delete(certificationId: number): Observable<Certification> {
    return this.apiService.delete(`/certificado/${certificationId}`);
  }

  disable(certificationId: number): Observable<Certification> {
    return this.apiService.patch(`/certificado/desativar/${certificationId}`);
  }
}
