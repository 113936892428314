import { AbstractControl, ValidatorFn } from '@angular/forms';

export function validateCep(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let cep = control.value;
    cep = cep.replace(/[^\d]+/g, '');
    if (!cep) {
      return null;
    }
    if (cep.length === 0) {
      return null;
    }

    if (cep.length !== 8) {
      return { cep: true };
    }

    return null;
  };
}