import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductLoss} from 'src/app/shared/models/product-loss.model';

import {ApiService} from './api.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductLossService {
  constructor(
    private apiService: ApiService
  ) {
  }

  protected jsonDataToResources(jsonData: any[]): ProductLoss[] {
    const elements: ProductLoss[] = [];
    jsonData.forEach((element) =>
      elements.push(Object.assign(new ProductLoss(), element))
    );
    return elements;
  }

  protected jsonDataToResource(jsonData: any): ProductLoss {
    return Object.assign(new ProductLoss(), jsonData);
  }

  getByFarm(farmId: number): Observable<ProductLoss[]> {
    return this.apiService.get(`/perda-produto?farmId=${farmId}`)
      .pipe(map(this.jsonDataToResources));
  }

  searchWithFilters(pageNumber: number, filters: any): Observable<any> {
    const params: any = {page: pageNumber, size: 15};
    Object.assign(params, filters);
    return this.apiService.get(`/perda-produto`, params);
  }

  create(productLosses: ProductLoss[]): Observable<{}> {
    return this.apiService
      .post(`/perda-produto`, {
        productLosses
      })
      .pipe(map(this.jsonDataToResource));
  }

  delete(productLossId: number): Observable<ProductLoss> {
    return this.apiService.delete(`/perda-produto/${productLossId}`);
  }
}
