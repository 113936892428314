import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Farm, FileMetadata } from 'src/app/shared';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FarmService {
  constructor(private apiService: ApiService) {}

  private jsonDataToResource(jsonData: any): Farm {
    const farm: Farm = Object.assign(new Farm(), jsonData);
    if (farm.images) {
      farm.images = farm.images.map((image) =>
        Object.assign(new FileMetadata(), image)
      );
    }
    if(farm.logo) {
      farm.logo = Object.assign(new FileMetadata(), farm.logo);
    }
    return farm;
  }

  private jsonDataToResources(jsonData: any[]): Farm[] {
    function jsonDataToResource(jsonData: any): Farm {
      const farm: Farm = Object.assign(new Farm(), jsonData);
      if (farm.images) {
        farm.images = farm.images.map((image) =>
          Object.assign(new FileMetadata(), image)
        );
      }
      if(farm.logo) {
        farm.logo = Object.assign(new FileMetadata(), farm.logo);
      }
      return farm;
    }
    return jsonData.map((element) => jsonDataToResource(element));
  }

  getAll(): Observable<Farm[]> {
    return this.apiService
      .get('/propriedade')
      .pipe(map(this.jsonDataToResources));
  }

  getAllForSelect(): Observable<Farm[]> {
    return this.apiService
      .get('/propriedade/select')
      .pipe(map(this.jsonDataToResources));
  }

  getById(id: number): Observable<Farm> {
    return this.apiService
      .get(`/propriedade/${id}`)
      .pipe(map(this.jsonDataToResource));
  }

  create(farm: Farm): Observable<Farm> {
    return this.apiService
      .post('/propriedade', farm)
      .pipe(map(this.jsonDataToResource));
  }

  update(farm: Farm): Observable<Farm> {
    return this.apiService
      .put(`/propriedade/${farm.id}`, farm)
      .pipe(map(this.jsonDataToResource));
  }

  disable(farmId: number): Observable<Farm> {
    return this.apiService.patch(`/propriedade/ativo/${farmId}`, {});
  }

  delete(farm: Farm): Observable<Farm> {
    return this.apiService.delete(`/propriedade/${farm.id}`);
  }
}
