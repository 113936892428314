import {Fund} from './fund.model';

export class TransferFundsModel {
  constructor(
    id?: number,
    description?: string,
    movedValue?: number,
    originFundId?: number,
    destinationFundId?: number,
    originFundName?: number,
    destinationFundName?: number,
    transactionDate?: string
  ) {
    this.id = id;
    this.description = description;
    this.movedValue = movedValue;
    this.originFundId = originFundId;
    this.destinationFundId = destinationFundId;
    this.originFundName = originFundName;
    this.destinationFundName = destinationFundName;
    this.transactionDate = transactionDate;
  }

  id?: number;
  description?: string;
  movedValue?: number;
  originFundId?: number;
  destinationFundId?: number;
  originFundName?: number;
  destinationFundName?: number;
  transactionDate?: string;
}
