import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  toggled = true;
  hasBackgroundImage = false;
  menus = [
    {
      title: 'Módulos',
      type: 'header',
    },
    {
      title: 'Rastreabilidade',
      icon: 'fa fa-map-marked-alt',
      active: false,
      type: 'dropdown',
      submenus: [
        {
          title: 'Propriedades',
          router: '/propriedade',
        },
        {
          title: 'Tipo de Produto',
          router: '/tipo-produto',
        },
        {
          title: 'Grupos Alimentares',
          router: '/grupo-alimentar',
        },
        {
          title: 'Unidades de Medida',
          router: '/unidade-medida',
        },
      ],
    },
    {
      title: 'Estoque',
      icon: 'fa fa-box-open',
      active: false,
      type: 'dropdown',
      submenus: [
        {
          title: 'Consultar Estoque',
          router: '/consulta-estoque',
        },
        {
          title: 'Entrada de Produtos',
          router: '/entrada-produto',
        },
        {
          title: 'Perda de Produtos',
          router: '/perda-produto',
        },
        {
          title: 'Propriedades',
          router: '/propriedade',
        },
        {
          title: 'Tipo de Produto',
          router: '/tipo-produto',
        },
        {
          title: 'Grupos Alimentares',
          router: '/grupo-alimentar',
        },
        {
          title: 'Unidades de Medida',
          router: '/unidade-medida',
        },
      ],
    },
    {
      title: 'Financeiro',
      icon: 'fa fa-dollar-sign',
      active: false,
      type: 'dropdown',
      submenus: [
        {
          title: 'Acerto de Comissão',
          router: '/acerto-comissao',
        },
        {
          title: 'Fornecedores',
          router: '/fornecedor',
        },
        {
          title: 'Transferência Contas',
          router: '/transferencia-contas',
        },
        {
          title: 'Duplicatas',
          router: '/duplicata',
        },
        {
          title: 'Empréstimos',
          router: '/emprestimo',
        },
        {
          title: 'Fundos',
          router: '/fundo',
        },
        {
          title: 'Extratos',
          router: '/fundo/extrato',
        },
        {
          title: 'Formas de Pagamento',
          router: '/forma-pagamento',
        },
      ],
    },
    {
      title: 'Vendas',
      icon: 'fa fa-money-check-alt',
      active: false,
      type: 'dropdown',
      submenus: [
        {
          title: 'Vendas',
          router: '/venda',
        },
        {
          title: 'Tabelas de Preço',
          router: '/tabela-preco',
        },
        {
          title: 'Clientes',
          router: '/cliente',
        },
      ],
    },
    {
      title: 'Atalhos',
      type: 'header',
    },
    {
      title: 'Realizar Vendas',
      icon: 'fa fa-shopping-cart',
      active: false,
      type: 'router',
      router: '/venda/novo',
    },
    {
      title: 'Consultar Estoque',
      icon: 'fa fa-box-open',
      active: false,
      type: 'router',
      router: '/consulta-estoque',
    },
    {
      title: 'Entrada de Produtos',
      icon: 'fa fa-truck-loading',
      active: false,
      type: 'router',
      router: '/entrada-produto',
    },
    {
      title: 'Perda de Produtos',
      icon: 'fa fa-arrow-down',
      active: false,
      type: 'router',
      router: '/perda-produto',
    },
    {
      title: 'Fechamento de Caixa',
      icon: 'fa fa-dollar-sign',
      active: false,
      type: 'router',
      router: '/fundo/fechamento-caixa',
    },
  ];
  constructor() {}

  toggle(): void {
    this.toggled = !this.toggled;
  }

  close(): void {
    this.toggled = true;
  }

  open(): void {
    this.toggled = false;
  }

  getSidebarState(): boolean {
    return this.toggled;
  }

  setSidebarState(state: boolean): void {
    this.toggled = state;
  }

  getMenuList(): any[] {
    return this.menus;
  }

  setBackgroundImage(hasBackgroundImage: boolean): void {
    this.hasBackgroundImage = hasBackgroundImage;
  }
}
