import { Address } from '.';

export class Customer {
  id?: number;
  name?: string;
  gender?: string;
  birthday?: string;
  cpf?: string;
  rg?: string;
  ufRG?: string;
  telephone?: string;
  address?: Address;
  active?: boolean;
  debt?: number;

  get keywords(): string {
    return `${this.name} ${this.cpf}`;
  }
}
