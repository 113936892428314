<div [ngClass]="{ toggled: getSideBarState(), 'page-wrapper': user }">
  <main [ngClass]="{ 'page-content padding-bottom': user }">
    <div [ngClass]="{ 'container-fluid': user }">
      <app-sidebar *ngIf="user"></app-sidebar>
      <router-outlet></router-outlet>
    </div>
    <div
      class="overlay"
      (click)="toggleSidebar($event)"
      [ngClass]="{ show: !getSideBarState() }"
    ></div>
  </main>
</div>
