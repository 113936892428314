import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrazilianStatesService {
  getStates(): string[] {
    return [
      'AC',
      'AL',
      'AP',
      'AM',
      'BA',
      'CE',
      'DF',
      'ES',
      'GO',
      'MA',
      'MT',
      'MS',
      'MG',
      'PA',
      'PB',
      'PR',
      'PE',
      'PI',
      'RJ',
      'RN',
      'RS',
      'RO',
      'RR',
      'SC',
      'SP',
      'SE',
      'TO'
    ];
  }
}

function injectable(): void {
  throw new Error('Function not implemented.');
}
