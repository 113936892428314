export class Expand{
  constructor(
    id?: number,
    value?: number,
    fundId?: number,
    description?: number,
    dueDate?: string,
    number?: string,
    supplierId?: number,
    supplierName?: string,
    payday?: string
  ) {
    this.id = id;
    this.value = value;
    this.fundId = fundId;
    this.description = description;
    this.dueDate = dueDate;
    this.number = number;
    this.supplierId = supplierId;
    this.supplierName = supplierName;
    this.payday = payday;
  }

  id?: number;
  value?: number;
  fundId?: number;
  description?: number;
  dueDate?: string;
  number?: string;
  supplierId?: number;
  supplierName?: string;
  payday?: string;
}
