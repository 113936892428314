import { FarmProduct } from './farm-product.model';

export class PriceTableItem {
  constructor(
    id?: number,
    product?: FarmProduct,
    price?: number,
    farmProductId?: number,
    priceTableId?: number
  ) {
    this.id = id;
    this.product = product;
    this.price = price;
    this.farmProductId = farmProductId;
    this.priceTableId = priceTableId;
  }

  id?: number;
  product?: FarmProduct;
  price?: number;
  farmProductId?: number;
  priceTableId?: number;

  get keywords(): string {
    return `${this.product.productType.productName} ${this.product.farm.name} ${this.product.barCode}`;
  }
}
