import { Address } from '.';

export class Farmer {
  id?: number;
  name?: string;
  gender?: string;
  birthday?: string;
  cpf?: string;
  rg?: string;
  ufRG?: string;
  telephone?: string;
  address?: Address;
}
