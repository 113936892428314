<div id="map" class="map-container">
  <div>
    <form class="row">
      <div class="col-sm-5">
        <label for="latitude" class="form-label text-light">Latitude</label>
        <input type="text" [formControl]="latitude" class="form-control form-control-sm" id="latitude"
               placeholder="Latitude">
      </div>
      <div class="col-sm-5">
        <label for="longitude" class="form-label text-light">Longitude</label>
        <input type="text" [formControl]="longitude" class="form-control form-control-sm" id="longitude"
               placeholder="Longitude">
      </div>
      <div class="col-sm-2 d-flex align-items-end">
        <button class="btn btn-sm btn-primary button shadow-none" (click)="handleGoToCoordinates(); $event.preventDefault();">Ir
        </button>
      </div>
    </form>
  </div>
</div>


