import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/helpers';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'propriedade',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'configuracoes',
    loadChildren: () =>
      import('./modules/config/config.module').then((m) => m.ConfigModule),
  },
  {
    path: 'grupo-alimentar',
    loadChildren: () =>
      import('./modules/stock/food-group/food-group.module').then(
        (m) => m.FoodGroupModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'unidade-medida',
    loadChildren: () =>
      import('./modules/stock/measurement-unity/measurement-unity.module').then(
        (m) => m.MeasurementUnityModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'tipo-produto',
    loadChildren: () =>
      import('./modules/stock/product-type/product-type.module').then(
        (m) => m.ProductTypeModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'fornecedor',
    loadChildren: () =>
      import('./modules/financial/supplier/supplier.module').then(
        (m) => m.SupplierModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'propriedade',
    loadChildren: () =>
      import('./modules/stock/farm/farm.module').then((m) => m.FarmModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'entrada-produto',
    loadChildren: () =>
      import('./modules/stock/product-entry/product-entry.module').then(
        (m) => m.ProductEntryModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'consulta-estoque',
    loadChildren: () =>
      import('./modules/stock/stock-query/stock-query.module').then(
        (m) => m.StockQueryModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'perda-produto',
    loadChildren: () =>
      import('./modules/stock/product-loss/product-loss.module').then(
        (m) => m.ProductLossModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'tabela-preco',
    loadChildren: () =>
      import('./modules/sales/prices-table/prices-table.module').then(
        (m) => m.PricesTableModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'cliente',
    loadChildren: () =>
      import('./modules/sales/customer/customer.module').then(
        (m) => m.CustomerModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'venda',
    loadChildren: () =>
      import('./modules/sales/sale/sale.module').then((m) => m.SaleModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'forma-pagamento',
    loadChildren: () =>
      import('./modules/financial/form-of-payment/form-of-payment.module').then(
        (m) => m.FormOfPaymentModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'fundo',
    loadChildren: () =>
      import('./modules/financial/fund/fund.module').then((m) => m.FundModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'duplicata',
    loadChildren: () =>
      import('./modules/financial/expand/expand.module').then(
        (m) => m.ExpandModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'transferencia-contas',
    loadChildren: () =>
      import(
        './modules/financial/transfer-between-funds/transfer-between-funds.module'
      ).then((m) => m.TransferBetweenFundsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'emprestimo',
    loadChildren: () =>
      import('./modules/financial/loan/loan.module').then((m) => m.LoanModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'acerto-comissao',
    loadChildren: () =>
      import('./modules/financial/farm-commission/farm-commission.module').then(
        (m) => m.FarmCommissionModule
      ),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
