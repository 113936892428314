import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  public findElementInArray(
    elementToFind: any,
    array: any[],
    key: string
  ): number {
    for (const i in array) {
      if (array[i][key] === elementToFind[key]) {
        return Number(i);
      }
    }
    return -1;
  }

  public removeAllExceptNumbers(word: string): string {
    return word.replace(/\D+/g, '');
  }

  public formatToBrlCurrency(num: number): string {
    return num.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  public formatToCPF(cpf: string): string {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  public formatToCNPJ(cnpj: string): string {
    return cnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1 $2 $3/$4-$5'
    );
  }

  public formatToPhoneNumber(phoneNumber: string): string {
    if (!phoneNumber) {
      return '';
    }
    return phoneNumber.replace(/^(\d\d)(\d{4,5})(\d{4}).*/, '($1) $2-$3');
  }

  public formatToDate(date: string): string {
    function addZero(num: number): string {
      if (num <= 9) {
        return '0' + num;
      } else {
        return '' + num;
      }
    }

    const actualDate = new Date(date);
    return (
      addZero(Number(actualDate.getDate().toString())) +
      '/' +
      addZero(actualDate.getMonth() + 1).toString() +
      '/' +
      actualDate.getFullYear()
    );
  }

  public getRandomString(length: number): string {
    const randomChars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }

  public allowOnlyNumbers(event: any): void {
    const regexpNumber = /[0-9]/;
    let inputCharacter = event.key;
    if (event.keyCode != 8 && !regexpNumber.test(inputCharacter)) {
      event.preventDefault();
    }
  }

  public preventNumbers(event: any): void {
    event = event || window.event;
    var charCode =
      typeof event.which == 'undefined' ? event.keyCode : event.which;
    var charStr = String.fromCharCode(charCode);
    if (/\d/.test(charStr)) {
      event.preventDefault();
    }
  }

  public getCurrentDate(): string {
    const d = new Date();
    return `${String(d.getDate()).padStart(2, '0')}/${String(
      d.getMonth() + 1
    ).padStart(2, '0')}/${String(d.getFullYear()).padStart(2, '0')}`;
  }

  public getCurrentDateInputFormat(): string {
    const d = new Date();
    return `${String(d.getFullYear()).padStart(2, '0')}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(
      d.getDate()
    ).padStart(2, '0')}`;
  }

  public getCurrentTime(): string {
    const d = new Date();
    return `${String(d.getHours()).padStart(2, '0')}h:${String(
      d.getMinutes()
    ).padStart(2, '0')}m:${String(d.getSeconds()).padStart(2, '0')}s`;
  }

  public sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public convertBase64(file: File): Promise<unknown> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event?.target?.result?.toString());
      };

      reader.readAsDataURL(file);
    });
  }
}
