import {OnInit, Component, Input} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import * as mapboxgl from 'mapbox-gl';
import {MapService} from 'src/app/shared';

@Component({
  selector: 'app-mapbox',
  templateUrl: './map-box.component.html',
  styleUrls: ['./map-box.component.css']
})

export class MapBoxComponent implements OnInit {

  @Input() parentForm: FormGroup;

  latitude: FormControl;
  longitude: FormControl;
  marker: mapboxgl.Marker;

  constructor(private map: MapService) {
    this.latitude = new FormControl(null);
    this.longitude = new FormControl(null);
  }

  ngOnInit(): void {
    this.map.buildMap();
    this.parentForm.addControl('latitude', this.latitude);
    this.parentForm.addControl('longitude', this.longitude);

    this.map.map.on('dblclick', e => this.setMarkerPosition(e.lngLat.lat, e.lngLat.lng));
  }

  createMarker(latitude: number, longitude: number): void {
    this.marker = new mapboxgl.Marker({color: 'red'})
      .setLngLat([longitude, latitude])
      .addTo(this.map.map);
  }

  setMarkerPosition(latitude: number, longitude: number): void {
    this.latitude.setValue(latitude);
    this.longitude.setValue(longitude);
    if (this.marker) {
      this.marker.setLngLat([longitude, latitude]);
    } else {
      this.createMarker(latitude, longitude);
    }
  }

  setPosition(latitude: number, longitude: number): void {
    this.map.map.setCenter([longitude, latitude]);
  }

  handleGoToCoordinates(): void {
    this.setMarkerPosition(this.latitude.value, this.longitude.value);
    this.setPosition(this.latitude.value, this.longitude.value);
  }

}
