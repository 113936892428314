import { Injectable } from '@angular/core';
import { PrintService, UsbDriver } from 'ng-thermal-print';
import { Customer, SaleAddress, SaleItem } from '../models';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class ThermalPrinterService {
  printerStatus: boolean = false;
  usbPrintDriver: UsbDriver = new UsbDriver();
  ipAddress: string = null;
  connectionAlreadyTried: boolean = false;

  constructor(private printService: PrintService, private utils: UtilsService) {
    this.printService.isConnected.subscribe((result) => {
      this.printerStatus = result;
    });
  }

  getLocalStoragePrinterPersistence(): void {
    if (localStorage.getItem('printer-device')) {
      const device = JSON.parse(localStorage.getItem('printer-device'));
      this.usbPrintDriver = new UsbDriver(device.vendorId, device.productId);
      const language = device.vendorId === 1305 ? 'StarPRNT' : 'ESC/POS';
      this.printService.setDriver(this.usbPrintDriver, language);
    }
  }

  async requestUsb(): Promise<void> {
    if (this.printerStatus || this.connectionAlreadyTried) {
      return;
    }
    await new Promise(async (resolve, reject) => {
      if (this.printerStatus) {
        resolve(true);
      }
      this.usbPrintDriver.requestUsb().subscribe(
        (result) => {
          const language = result.vendorId === 1305 ? 'StarPRNT' : 'ESC/POS';
          localStorage.setItem(
            'printer-device',
            JSON.stringify({
              vendorId: result.vendorId,
              productId: result.productId,
            })
          );
          this.usbPrintDriver = new UsbDriver(
            result.vendorId,
            result.productId
          );
          this.printService.setDriver(this.usbPrintDriver, language);
          resolve(result);
        },
        () => resolve(true)
      );
    });
  }

  printHeader(): void {
    this.printService
      .setBold(true)
      .writeLine('Universo Organico')
      .setBold(false) 
      .feed(1)
      .writeLine(`Data: ${this.utils.getCurrentDate()}`)
      .writeLine(`Hora: ${this.utils.getCurrentTime()}`)
      .feed(1)
      .flush();
  }

  requestPrintSale(
    saleItems: SaleItem[],
    totalSale: number,
    totalPayed: number,
    saleCustomer?: Customer,
    deliveryAddress?: SaleAddress
  ): void {
    if (this.printerStatus) {
      this.printSale(
        saleItems,
        totalSale,
        totalPayed,
        saleCustomer,
        deliveryAddress
      );
    }
  }

  async printSale(
    saleItems: SaleItem[],
    totalSale: number,
    totalPayed: number,
    saleCustomer?: Customer,
    deliveryAddress?: SaleAddress
  ): Promise<void> {
    this.printService.init();
    await this.utils.sleep(100);
    this.printHeader();
    await this.utils.sleep(100);
    this.printSaleItems(saleItems);
    await this.utils.sleep(100);
    if (saleCustomer) {
      this.printCustomer(saleCustomer);
    }
    await this.utils.sleep(100);
    if (deliveryAddress) {
      this.printSaleAddress(deliveryAddress);
    }
    await this.utils.sleep(100);
    this.printSalePayments(totalSale, totalPayed);
  }

  printSaleItems(saleItems: SaleItem[]): void {
    this.printService.setBold(true).writeLine('Itens da Venda').setBold(false);
    saleItems.forEach((item) =>
      this.printService.writeLine(
        `${item.amount} ${item.measurementUnity} ${item.productName}`
      )
    );
    this.printService.feed(1).flush();
  }

  printCustomer(customer: Customer): void {
    this.printService
      .setBold(true)
      .writeLine('Cliente da Venda')
      .setBold(false);

    this.printService
      .writeLine(`Nome: ${customer.name}`)
      .writeLine(`CPF: ${this.utils.formatToCPF(customer.cpf)}`)
      .writeLine(
        `Telefone: ${this.utils.formatToPhoneNumber(
          customer.telephone
        )}`
      )
      .feed(1)
      .flush();
  }

  printSaleAddress(deliveryAddress: SaleAddress): void {
    this.printService
      .setBold(true)
      .writeLine('Endereço de Entrega')
      .setBold(false);

    if (
      `${deliveryAddress.address.cep}${deliveryAddress.address.street}${deliveryAddress.address.houseNumber}`
        .length > 0
    ) {
      this.printService.writeLine(
        `${deliveryAddress.address.cep} ${deliveryAddress.address.street} ${deliveryAddress.address.houseNumber}`
      );
    }
    if (
      `${deliveryAddress.address.city} ${deliveryAddress.address.state}`
        .length > 0
    ) {
      this.printService.writeLine(
        `${deliveryAddress.address.city} ${deliveryAddress.address.state}`
      );
    }
    if (`${deliveryAddress.instructions}`.length > 0) {
      this.printService.writeLine(`${deliveryAddress.instructions}`);
    }
    this.printService.feed(1).flush();
  }

  printSalePayments(totalPayed: number, totalSale: number): void {
    this.printService.setBold(true).writeLine('Pagamento').setBold(false);
    this.printService
      .writeLine(
        `Total Pago: ${this.utils
          .formatToBrlCurrency(totalPayed)
          .replace(/\s/g, '')}`
      )
      .writeLine(
        `Troco: ${this.utils
          .formatToBrlCurrency(totalSale - totalPayed)
          .replace(/\s/g, '')}`
      )
      .setBold(true)
      .writeLine(
        `Total: ${this.utils.formatToBrlCurrency(totalSale).replace(/\s/g, '')}`
      )
      .setBold(false)
      .cut('full')
      .flush();
  }
}
