import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SidebarService} from './core/sidebar/sidebar.service';
import {AccountService} from './core';
import {Observable} from 'rxjs';
import {User} from './shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'frontend';
  user: User = null;

  constructor(
    public sidebarService: SidebarService,
    public router: Router,
    private route: ActivatedRoute,
    private accountService: AccountService
  ) {
    this.accountService.user.subscribe(
      newValue => this.user = newValue
    );
  }

  ngOnInit(): void {
  }

  toggleSidebar(event: any): void {
    event.preventDefault();
    this.sidebarService.setSidebarState(!this.sidebarService.getSidebarState());
  }

  getSideBarState(): any {
    return this.sidebarService.getSidebarState();
  }

  hideSidebar(): void {
    this.sidebarService.setSidebarState(true);
  }
}
