export class ProductLoss {
  constructor(
    id?: number,
    farmName?: string,
    farmProductName?: string,
    farmProductId?: number,
    farmerName?: string,
    amount?: number,
    measurementUnity?: string,
    reason?: string,
    lossDate?: string
  ) {
    this.id = id;
    this.farmName = farmName;
    this.farmerName = farmerName;
    this.farmProductName = farmProductName;
    this.farmProductId = farmProductId;
    this.amount = amount;
    this.measurementUnity = measurementUnity;
    this.reason = reason;
    this.lossDate = lossDate;
  }
  id?: number;
  farmName?: string;
  farmerName?: string;
  farmProductName?: string;
  farmProductId?: number;
  amount?: number;
  measurementUnity?: string;
  reason?: string;
  lossDate?: string;
}
