import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Customer, Supplier} from 'src/app/shared';

import {ApiService} from './api.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SupplierService {
  constructor(
    private apiService: ApiService
  ) {
  }

  protected jsonDataToResources(jsonData: any[]): Supplier[] {
    const elements: Supplier[] = [];
    jsonData.forEach((element) =>
      elements.push(Object.assign(new Supplier(), element))
    );
    return elements;
  }

  protected jsonDataToResource(jsonData: any): Supplier {
    return Object.assign(new Supplier(), jsonData);
  }

  getAll(): Observable<Supplier[]> {
    return this.apiService.get('/fornecedor')
      .pipe(map(this.jsonDataToResources));
  }

  getById(id: number): Observable<Supplier> {
    return this.apiService
      .get(`/fornecedor/${id}`)
      .pipe(map(this.jsonDataToResource));
  }

  create(supplier: Supplier): Observable<Supplier> {
    return this.apiService
      .post('/fornecedor', supplier)
      .pipe(map(this.jsonDataToResource));
  }

  update(supplier: Supplier): Observable<Supplier> {
    return this.apiService
      .put(`/fornecedor/${supplier.id}`, supplier)
      .pipe(map(this.jsonDataToResource)
      );
  }

  disable(supplierId: number): Observable<Supplier>{
    return this.apiService.patch(`/fornecedor/ativo/${supplierId}`, {});
  }

  delete(supplier: Supplier): Observable<Supplier> {
    return this.apiService.delete(`/fornecedor/${supplier.id}`);
  }
}
