import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Sale } from 'src/app/shared';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SaleService {
  constructor(private apiService: ApiService) {}

  protected jsonDataToResources(jsonData: any[]): Sale[] {
    const elements: Sale[] = [];
    jsonData.forEach((element) =>
      elements.push(Object.assign(new Sale(), element))
    );
    return elements;
  }

  protected jsonDataToResource(jsonData: any): Sale {
    return Object.assign(new Sale(), jsonData);
  }

  getAll(): Observable<Sale[]> {
    return this.apiService.get('/venda').pipe(map(this.jsonDataToResources));
  }

  searchWithFilters(pageNumber: number, filters: any[]): Observable<any> {
    const params: any = { page: pageNumber, size: 5 };
    Object.assign(params, filters);
    return this.apiService.get(`/venda`, params);
  }

  getById(id: number): Observable<Sale> {
    return this.apiService
      .get(`/venda/${id}`)
      .pipe(map(this.jsonDataToResource));
  }

  create(sale: Sale): Observable<Sale> {
    return this.apiService
      .post('/venda', sale)
      .pipe(map(this.jsonDataToResource));
  }

  update(sale: Sale): Observable<Sale> {
    return this.apiService
      .put(`/venda/${sale.id}`, sale)
      .pipe(map(this.jsonDataToResource));
  }

  delete(saleId: number): Observable<Sale> {
    return this.apiService.delete(`/venda/${saleId}`);
  }

  getByDay(formOfPayment?: string): Observable<{
    total: number;
    totalSalesValue: number;
    sales: Sale[];
  }> {
    if (formOfPayment) {
      return this.apiService.get(`/venda/dia?formOfPayment=${formOfPayment}`);
    }
    return this.apiService.get('/venda/dia');
  }

  getTotalSales(
    initialDate: string,
    finalDate: string
  ): Observable<{ total: number }> {
    return this.apiService.get(
      `/venda/total?initialDate=${initialDate}&finalDate=${finalDate}`
    );
  }
}
