import { Farm, SaleItem } from '.';

export class FarmCommission {
  constructor(
    id?: number,
    farm?: Farm,
    valueAssociation?: number,
    valuePaidFarm?: number,
    payDate?: string,
    fixedMonthlyFee?: number,
    referringMonth?: string
  ) {
    this.id = id;
    this.farm = farm;
    this.valuePaidFarm = valuePaidFarm;
    this.valueAssociation = valueAssociation;
    this.payDate = payDate;
    this.fixedMonthlyFee = fixedMonthlyFee;
    this.referringMonth = referringMonth;
  }
  id?: number;
  farm?: Farm;
  valuePaidFarm?: number;
  valueAssociation?: number;
  payDate?: string;
  fixedMonthlyFee?: number;
  referringMonth?: string;
}
